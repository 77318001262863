import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import './helplineNumber.scss';

const HelplineNumber = props => {
    const intl = useIntl();
    const { textDataLocator, phonenumberDataLocator, phoneNumber, hideDotNextToNumber, customClass, message } = props;
    let hrefPhone = '';
    let formattedPhoneNumber = '';

    if (phoneNumber) {
        formattedPhoneNumber = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6);
        hrefPhone = `tel:${formattedPhoneNumber}`;
    }

    return (
        <>
            {phoneNumber && (
                <div className={`helplineNumberContainer ${customClass}`} data-testid={textDataLocator}>
                    <span>{message} </span>
                    <a
                        href={hrefPhone}
                        className="helplineNumberLink"
                        title={`${intl.formatMessage({
                            id: 'call-our-helpline-number-at'
                        })}`}
                        data-testid={phonenumberDataLocator}
                        tabIndex={0}>
                        {formattedPhoneNumber}
                    </a>
                    {!hideDotNextToNumber && '.'}
                </div>
            )}
        </>
    );
};

HelplineNumber.propTypes = {
    customClass: PropTypes.string,
    message: PropTypes.string,
    textDataLocator: PropTypes.string,
    phonenumberDataLocator: PropTypes.string,
    phoneNumber: PropTypes.string,
    hideDotNextToNumber: PropTypes.bool
};

HelplineNumber.defaultProps = {
    customClass: '',
    message: '',
    textDataLocator: '',
    phonenumberDataLocator: '',
    phoneNumber: '',
    hideDotNextToNumber: false
};

export default HelplineNumber;
