import { func, object, string } from 'prop-types';
import React from 'react';
import useCheckLocationEmpty from '../../../../../hooks/useCheckLocationEmpty';
import { orderSummaryDataLocators } from '../../dataLocators';
import OrderSummaryLineItem from '../orderSummaryLineItem/OrderSummaryLineItem';
import RoundTripFee from '../roundTripFee/RoundTripFee';
import '../../orderSummaryFactory.scss';

const CartSummaryView = props => {
    const {
        orderSummaryIntl,
        orderTotals,
        source,
        deliveryChargeFlag,
        handleRoundTripAndEstimateFlow,
        isDelivery,
        isInStorePickupFromSessionStorage
    } = props;

    const {
        title,
        rentalSubtotal,
        purchaseSubtotal,
        rentalProtectionPlan,
        deliveryPickUpCharges,
        prepayFuelOption,
        envFees,
        otherFees,
        taxes,
        showDeliveryEstimate,
        dispatch
    } = orderTotals;

    const { isRentalDetailsAvailable } = useCheckLocationEmpty();

    const renderInStoreOrDeliveryFee = () => {
        return (
            <>
                {isInStorePickupFromSessionStorage ? (
                    showDeliveryEstimate && deliveryPickUpCharges ? null : (
                        <OrderSummaryLineItem
                            itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            // itemLabelDataTestId={orderSummaryDataLocators.quoteSummary_rentalSubtotal_lbl} //to be added
                            isTooltipIcon
                            tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            itemValue={
                                !showDeliveryEstimate && isRentalDetailsAvailable()
                                    ? orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup-value' })
                                    : '-'
                            }
                            // itemValueDataTestId={orderSummaryDataLocators.quoteSummary_rentalSubtotal_dynvalue} // to be added
                            dispatch={dispatch}
                        />
                    )
                ) : (
                    <RoundTripFee
                        source={source}
                        deliveryChargeFlag={deliveryChargeFlag}
                        deliveryPickUpCharges={deliveryPickUpCharges}
                        showDeliveryEstimate={showDeliveryEstimate}
                        handleRoundTripAndEstimateFlow={handleRoundTripAndEstimateFlow}
                        isDelivery={isDelivery}
                        dispatch={dispatch}></RoundTripFee>
                )}
            </>
        );
    };

    return (
        <>
            <section className="ordersummary">
                <div className={`ordersummary-top`}>
                    <h5
                        data-testid={orderSummaryDataLocators.miniCartOverlay_orderSummary_txt}
                        className="ordersummary-top-title">
                        {title}
                    </h5>
                </div>

                <div className={'items group'}>
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:rental-subtotal' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_rentalSubtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={rentalSubtotal}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_rentalSubtotal_dynvalue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:purchases-subtotal' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_purchase_subtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={purchaseSubtotal}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_purchase_subtotal_dynValue}
                        dispatch={dispatch}
                    />
                    {renderInStoreOrDeliveryFee()}
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:rental-protection-plan' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_rpp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({
                            id: 'order-summary:rental-protection-plan'
                        })}
                        itemValue={rentalProtectionPlan}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOVerlay_rppBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_fcp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({
                            id: 'order-summary:fuel-convenience-plan'
                        })}
                        itemValue={prepayFuelOption}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOVerlay_fcpBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemValue={envFees}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:other-fee' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_lbl}
                        isTooltipIcon={false}
                        itemValue={otherFees}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:taxes-text' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_taxes_lbl}
                        isTooltipIcon={false}
                        itemValue={taxes}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOVerlay_taxesBlank_value}
                        dispatch={dispatch}
                        wrapperClasses={'ordersummary-item--taxes'}
                    />
                </div>
            </section>
        </>
    );
};

export default React.memo(CartSummaryView);

CartSummaryView.propTypes = {
    orderTotals: object,
    source: string,
    deliveryChargeFlag: string,
    handleRoundTripAndEstimateFlow: func,
    isDelivery: func
};
CartSummaryView.defaultProps = {
    orderTotals: {},
    source: '',
    deliveryChargeFlag: '',
    handleRoundTripAndEstimateFlow: () => {},
    isDelivery: () => {}
};
