import { bool, string } from 'prop-types';
import React from 'react';
import TooltipIcon from '../tooltipIcon/TooltipIcon';

const OrderSummaryLineItem = props => {
    const {
        isBoldLabel,
        itemLabel,
        itemLabelDataTestId,
        itemValue,
        itemValueDataTestId,
        isTooltipIcon,
        tooltipIconMessage,
        wrapperClasses
    } = props;

    return (
        <div className={`ordersummary-item ${wrapperClasses}`}>
            <span className="ordersummary-item-key" data-testid={itemLabelDataTestId}>
                {' '}
                {isBoldLabel ? <b>{itemLabel}</b> : itemLabel}
                {isTooltipIcon && <TooltipIcon feeTypeName={tooltipIconMessage} />}
            </span>
            <span className="ordersummary-item-value" data-testid={itemValueDataTestId}>
                <strong>{itemValue}</strong>
            </span>
        </div>
    );
};

export default React.memo(OrderSummaryLineItem);

OrderSummaryLineItem.propTypes = {
    isBoldLabel: bool,
    itemLabel: string,
    itemLabelDataTestId: string,
    itemValue: string,
    itemValueDataTestId: string,
    isTooltipIcon: bool,
    tooltipIconMessage: string,
    wrapperClasses: string
};
OrderSummaryLineItem.defaultProps = {
    isBoldLabel: false,
    itemLabel: '',
    itemLabelDataTestId: '',
    itemValue: '',
    itemValueDataTestId: '',
    isTooltipIcon: false,
    tooltipIconMessage: '',
    wrapperClasses: ''
};
