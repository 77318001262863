import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CollapsedView } from '../../global/modules/stepForm';
import EditOptionalPlans from './EditOptionalPlans';
import ReadOnlyOptionalPlans from './ReadOnlyOptionalPlans';
import { useCartState } from '../../../contexts/cart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCheckUser } from '../../../hooks/useCheckUser';
import {
    SET_OPTIONAL_PLANS_CHECKED,
    SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE
} from '../../../aem-core-components/actions/constants';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import classes from '../checkoutAndOrderSummary/checkoutAndOrderSummary.css';
import { getURLParams, isPaymentSectionVisited } from '../../global/utils/commonUtils';
import { USER_TYPE } from '../../../constants/userDetailsConstants';

const OptionalPlans = props => {
    const [isRPPCheckboxVisible, setIsRPPCheckboxVisible] = useState(true);
    const [isFuelCheckboxVisible, setIsFuelCheckboxVisible] = useState(true);
    const intl = useIntl();
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { currentStep, handleStepChange, formStep, isCustomAccount } = props;
    const stepNumber = formStep.OPTIONAL_PLANS;
    const [{ userAccount, cart, clickedMakeChanges }, dispatch] = useCartState();
    const userType = useCheckUser();
    const { fuelCharges, rppCharges } = cart?.estimatesResponse?.estimate?.totals || {};

    useEffect(() => {
        const params = getURLParams();
        if (clickedMakeChanges || !isPaymentSectionVisited()) {
            const chargeRPP = userProfile?.accounts?.filter(
                account => account?.account === userAccount?.accountNumber
            )[0]?.chargeRPP;
            dispatch({
                type: SET_OPTIONAL_PLANS_CHECKED,
                isRPPChecked: setRPPChecked(chargeRPP),
                isFuelChargeChecked: false
            });
        }
    }, [userProfile, userAccount?.accountNumber, rppCharges, fuelCharges]);

    useEffect(() => {
        if (parseInt(fuelCharges) === 0 || !fuelCharges) {
            setIsFuelCheckboxVisible(false);
        } else {
            setIsFuelCheckboxVisible(true);
        }
    }, [fuelCharges]);
    useEffect(() => {
        dispatch({
            type: SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE,
            isRPPCheckboxVisible: isRPPCheckboxVisible,
            isFuelCheckboxVisible: isFuelCheckboxVisible
        });
    }, [isRPPCheckboxVisible, isFuelCheckboxVisible, userAccount?.accountNumber]);

    const setRPPChecked = chargeRPP => {
        if (userType === USER_TYPE.CREDIT) {
            if (parseInt(rppCharges) > 0 && rppCharges) {
                if (chargeRPP) {
                    setIsRPPCheckboxVisible(true);
                    return true;
                } else {
                    setIsRPPCheckboxVisible(false);
                    return false;
                }
            } else {
                setIsRPPCheckboxVisible(false);
                return false;
            }
        } else {
            if (parseInt(rppCharges) > 0 && rppCharges) {
                setIsRPPCheckboxVisible(true);
                return true;
            } else {
                setIsRPPCheckboxVisible(false);
                return false;
            }
        }
    };

    return (
        <div className={classes.root}>
            {currentStep < stepNumber && (
                <CollapsedView
                    stepNumber={stepNumber}
                    stepTitle={intl.formatMessage({ id: 'checkout:optional-plans-title' })}
                    testId={checkoutDatalocator.checkout_optionplans_link_testid}
                />
            )}
            {currentStep === stepNumber && (
                <EditOptionalPlans
                    currentStep={currentStep}
                    formStep={formStep}
                    currentTitle={intl.formatMessage({ id: 'checkout:optional-plans-title' })}
                    handleStepChange={handleStepChange}
                    testId={checkoutDatalocator.checkout_optionplans_link_testid}
                    isRPPCheckboxVisible={isRPPCheckboxVisible}
                    isCustomAccount={isCustomAccount}
                    isFuelCheckboxVisible={isFuelCheckboxVisible}
                />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyOptionalPlans
                    testId={checkoutDatalocator.checkout_optional_stage_edit_testid}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                />
            )}
        </div>
    );
};

export default OptionalPlans;
