import React from 'react';
import './createJobsite.scss';
import ReadOnlyCreateJobsite from './ReadOnlyCreateJobsite';
import EditCreateJobsite from './EditCreateJobsite';

const CreateJobsite = ({ currentStep, handleStepChange, currentOffSet, formStep }) => {
    return (
        <div className="createjobsite-root">
            {currentStep > formStep.CREATE_JOBSITE ? (
                <ReadOnlyCreateJobsite
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                    currentStep={currentStep}
                />
            ) : (
                <EditCreateJobsite
                    currentStep={currentStep}
                    handleStepChange={handleStepChange}
                    currentOffSet={currentOffSet}
                    formStep={formStep}
                />
            )}
        </div>
    );
};

export default CreateJobsite;
