import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import useCheckout from '../../../hooks/useCheckout';
import { useCheckUser } from '../../../hooks/useCheckUser';
import Person from '../../../resources/images/person.svg';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { ReadOnlyView } from '../../global/modules/stepForm';
import { logError } from '../../global/utils/logger';
import '../checkout.scss';
import { useFilterState } from '../../cap';

const ReadOnlyProjectInfo = props => {
    const { currentStep, handleStepChange, formStep } = props;
    const intl = useIntl();
    const [{ projectInfo }] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();
    const { isInStorePickup } = viewCart;
    const { clearCheckout } = useCheckout();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const userType = useCheckUser();
    const { sendEventsForClick } = useAnalyticsContext();
    const editHandler = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                ` ${VARIABLE_CONFIG.EVENT_LABEL.DELIVERY_PROJECT_DETAILS} edit`
            );
        } catch (error) {
            logError(error, false, 'editHandler');
        }
        setShowAlertModal(true);
    };

    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        handleStepChange(formStep.PROJECT_DETAILS);
        clearCheckout(formStep, formStep.PROJECT_DETAILS);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
    };

    const renderReadOnlyBody = () => {
        return (
            <>
                <div className="order-details-item">
                    <Person />
                    <div className="order-details-desc readonly-project-info">
                        {!isInStorePickup && (
                            <>
                                <div className="order-details-title">{projectInfo.primaryContactName}</div>
                                <small className="order-details-breakText">{projectInfo.phoneNumber}</small>
                                {projectInfo.accessNotes ? (
                                    <small className="order-details-breakText">
                                        <i>{`"${projectInfo.accessNotes}"`}</i>
                                    </small>
                                ) : null}
                            </>
                        )}
                        {projectInfo.poNumber ? (
                            <small className="order-details-breakText">{`PO: ${projectInfo.poNumber}`}</small>
                        ) : null}
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            <ReadOnlyView
                children={renderReadOnlyBody()}
                title={intl.formatMessage({ id: 'checkout:project-info' })}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};
export default ReadOnlyProjectInfo;
