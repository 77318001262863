import React from 'react';
import { useIntl } from 'react-intl';
import { CollapsedView } from '../../global/modules/stepForm';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';

const CollapsedAccessoriesAndAddons = ({formStep}) => {
    const intl = useIntl();
    return (
        <CollapsedView
            stepNumber={formStep.ACCESSORIES_AND_ADDONS}
            stepTitle={intl.formatMessage({ id: 'checkout:accessories-and-addons-title' })}
            testId={checkoutDatalocator.checkout_howtogetyourorder_link_testid}
        />
    );
};
export default CollapsedAccessoriesAndAddons;
