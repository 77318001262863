import React from 'react';
import './collapsedView.scss';
import { string, number } from 'prop-types';

const CollapsedView = props => {
    const { stepNumber, stepTitle, testId } = props;

    return (
        <div className="collapsed-header">
            <span className="collapsed-orderSummaryHeadingNumber">{stepNumber}</span>
            <h5 aria-label={stepTitle} className="collapsed-orderSummaryHeading" data-testid={testId}>
                {stepTitle}
            </h5>
        </div>
    );
};

CollapsedView.propTypes = {
    stepTitle: string,
    stepNumber: number,
    testId: string
};

CollapsedView.defaultProps = {
    stepTitle: '',
    stepNumber: 0,
    testId: ''
};

export default CollapsedView;
