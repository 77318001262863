import { gql } from '@apollo/client';

const mutation_consumables = gql`
    mutation getConsumables($pcId: String!, $account: String!, $jobNumber: String!, $items: [String!]!) {
        getConsumables(pcId: $pcId, account: $account, jobNumber: $jobNumber, items: $items) {
            status
            message
            suggestedItems {
                cat
                class
                rentables {
                    rentalCat
                    rentalClass
                    equipmentNumber
                    sequence
                    description
                    forceItem
                    minQuantity
                    minRate
                    dayRate
                    wkRate
                    moRate
                    availQuantity
                    IsSerialized
                    product {
                        sku
                        product_page_url
                        category_name
                        categories {
                            category_page_url
                            id
                            name
                        }
                        thumbnail {
                            url
                        }
                    }
                }
                consumables {
                    inventoryClass
                    invClassDescription
                    specGroups {
                        specNumber
                        title
                        specDescription
                        minPrice
                        maxPrice
                        items {
                            itemNumber
                            stockClass
                            oemStockNumber
                            itemDescription
                            sequence
                            forceItem
                            minQuantity
                            sellingPrice
                            availQuantity
                            rentalCat
                            rentalClass
                            priceSchedule
                            IsSerialized
                            binNumber1
                            binNumber2
                            binNumber3
                        }
                    }
                }
            }
        }
    }
`;
export default mutation_consumables;
