import React from 'react';
import { object, string } from 'prop-types';
import OrderSummaryLineItem from '../orderSummaryLineItem/OrderSummaryLineItem';
import RoundTripFee from '../roundTripFee/RoundTripFee';
import Alert from '../../../../global/atoms/alert/alert';
import { quoteSummaryDataLocators } from '../../dataLocators';
import '../../orderSummaryFactory.scss';

const QuoteSummaryView = props => {
    const { orderSummaryIntl, orderTotals, source, quoteDetailsData, shouldShowTbdAlert } = props;
    const {
        title,
        rentalSubtotal,
        purchaseSubtotal,
        rentalProtectionPlan,
        prepayFuelOption,
        deliveryPickUpCharges,
        estimatedSubtotal,
        envFees,
        otherFees,
        taxes,
        subtotalValue,
        dispatch
    } = orderTotals;

    const { cart: quoteCartData, data: quoteData } = quoteDetailsData || {};

    return (
        <>
            <div className="ordersummary quotesummary">
                <div className={`ordersummary-top quotesummary-top`}>
                    <h5
                        data-testid={quoteSummaryDataLocators.quoteSummary_quoteSummary_txt}
                        className="ordersummary-top-quote-title">
                        {title}
                    </h5>
                </div>
                <div className={'ordersummary-top-checkout'}>
                    <div
                        data-testid={quoteSummaryDataLocators.quoteSummary_taxesFees_txt}
                        className="ordersummary-top-content">
                        {orderSummaryIntl.formatMessage({ id: 'order-summary:tax-and-fees-warning-text' })}
                    </div>
                </div>
                <div className={'ordersummary-items-checkout'}>
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:rental-subtotal' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_rentalSubtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={rentalSubtotal}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_rentalSubtotal_dynvalue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:purchases-subtotal' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_purchase_subtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={purchaseSubtotal}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_purchase_subtotal_dynValue}
                        dispatch={dispatch}
                    />
                    {quoteData?.isDelivery ? (
                        <RoundTripFee
                            source={source}
                            deliveryPickUpCharges={deliveryPickUpCharges}
                            dispatch={dispatch}></RoundTripFee>
                    ) : (
                        <OrderSummaryLineItem // need to confirm styling while testing
                            itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_instorePickup_lbl}
                            isTooltipIcon
                            tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            itemValue={orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup-value' })}
                            itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_instorePickupFree_txt}
                            dispatch={dispatch}
                        />
                    )}
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:rental-protection-plan' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_rpp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({
                            id: 'order-summary:rental-protection-plan'
                        })}
                        itemValue={rentalProtectionPlan}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_rppBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_fcp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({
                            id: 'order-summary:fuel-convenience-plan'
                        })}
                        itemValue={prepayFuelOption}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_fcpBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_srp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemValue={envFees}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_srp_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:other-fee' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_srp_lbl}
                        isTooltipIcon={false}
                        itemValue={otherFees}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_srp_dynValue}
                        dispatch={dispatch}
                    />
                </div>
                <div className="ordersummary-checkout">
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:subtotal' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_subtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={subtotalValue}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_subtotal_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:taxes' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_taxes_lbl}
                        isTooltipIcon={false}
                        itemValue={taxes}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_taxesBlank_value}
                        dispatch={dispatch}
                    />
                </div>
                <div className="ordersummary-total">
                    <OrderSummaryLineItem
                        isBoldLabel
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:total' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_total_lbl}
                        isTooltipIcon={false}
                        itemValue={estimatedSubtotal}
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_total_dynValue}
                        dispatch={dispatch}
                    />
                </div>
                {shouldShowTbdAlert() && (
                    <Alert
                        type={'warning'}
                        message={orderSummaryIntl.formatMessage({ id: 'order-summary:checkout-tbd-alert' })}
                    />
                )}
            </div>
        </>
    );
};

export default React.memo(QuoteSummaryView);

QuoteSummaryView.propTypes = {
    orderTotals: object,
    source: string
};
QuoteSummaryView.defaultProps = {
    orderTotals: {},
    source: ''
};
