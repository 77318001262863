export const validateField = (required, value, setErrorMsg, onValidate, matcher, submit, setSubmit, requiredErrorMsg, formatErrorMsg, onErrorAnalytics = ()=>{}) => {
    if (submit) {
        if (required && !value?.trim()) {
            setErrorMsg(requiredErrorMsg);
            onErrorAnalytics(requiredErrorMsg);
            onValidate(false);
            setSubmit(false);
        } else if (value?.trim()) {
            if (!matcher(value)) {
                setErrorMsg(formatErrorMsg);
                onErrorAnalytics(formatErrorMsg);
                onValidate(false);
                setSubmit(false);
              } else {
                setErrorMsg('');
                onValidate(true);
              }
        }
    }
};

export const validateNameField = (required, value, setErrorMsg, onValidate, matcher, submit, setSubmit, requiredErrorMsg, formatErrorMsg, onErrorAnalytics = ()=>{}) => {
    const inputVal = value?.trim();
    if (submit) {
        if (required && !inputVal) {
            setErrorMsg(requiredErrorMsg);
            onErrorAnalytics(requiredErrorMsg);
            onValidate(false);
            setSubmit(false);
        } else if (inputVal) {
            if (!matcher(inputVal)) {
                setErrorMsg(formatErrorMsg);
                onErrorAnalytics(formatErrorMsg);
                onValidate(false);
                setSubmit(false);
              } else {
                setErrorMsg('');
                onValidate(true);
              }
        }
    }
};
