import { bool, func, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import EditIcon from '../../../../resources/images/edit.svg';
import './editButton.scss';
export default function EditButton({ handleOnClick, testId, customButtonAriaLabel, label }) {
    const intl = useIntl();
    const handleEditIconClick = () => {
        handleOnClick();
    };
    return (
        <button
            className="edit-button"
            onClick={handleEditIconClick}
            data-testid={testId}
            aria-label={label || 'Edit ' + customButtonAriaLabel}>
            <span className="edit-label">
                {label || intl.formatMessage({ id: 'account:address-edit', defaultMessage: 'Edit' })}
            </span>
            <span className="edit-icon">
            <EditIcon />
            </span>
        </button>
    );
}

EditButton.propTypes = {
    handleOnClick: func,
    label: string
};

EditButton.defaultProps = {
    handleOnClick: () => {},
    label: ''
};
