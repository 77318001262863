import { gql } from '@apollo/client';
const mutation_review_duplicates = gql`
    mutation checkJobsiteInMagentoCache($search: String!) {
        checkJobsiteInMagentoCache(input: { search: $search }) {
            success
            message
            data {
                projectId
                projectName
                deliveryInstructions1
                customerPO
                CJDLVY
                contact {
                    name
                    phone
                    id
                }
                address {
                    city
                    line1
                    line2
                    line3
                    state
                    zip
                    latitude
                    longitude
                    country
                    locationPC
                }
            }
        }
    }
`;
export default mutation_review_duplicates;
