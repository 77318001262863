import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import AccessoriesForRent from './AccessoriesForRent';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import '../accessoriesAndAddons.scss';

const Accessories = props => {
    const { consumables, selectedItems, addAccessory } = props;
    const intl = useIntl();
    return (
        <>
            <h6
                className="modal-rental-equipment-header"
                data-testid={checkoutDatalocator.checkout_rental_equipment_accessories_heading}>
                {intl.formatMessage({ id: 'checkout:rental-equipments-accessories-header' })}
            </h6>
            <div className="rental-accessories-container">
                <AccessoriesForRent
                    selectedItems={selectedItems}
                    rentables={consumables?.selectedRentalEquipment?.equipmentItems?.rentables}
                    addAccessory={addAccessory}></AccessoriesForRent>
            </div>
        </>
    );
};

export default memo(Accessories);
