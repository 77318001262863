import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CREDIT_CUSTOM_ACCOUNT_INFO } from '../../../../../aem-core-components/actions/constants';
import Button from '../../../../global/atoms/button/button';
import Checkbox from '../../../../global/atoms/Checkbox/Checkbox';
import Input from '../../../../global/atoms/input/Input';
import { getURLParams, isPaymentSectionVisited, validateCustomField } from '../../../../global/utils/commonUtils';
import { useCartState } from '../../../../../contexts/cart';
import { useUserContext } from '../../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../../config/GoogleTagManagerEvents';
import { getProducts } from '../../../../../constants/analyticsConstants/getProducts';
import { VARIABLE_CONFIG } from '../../../../../constants/analyticsConstants/Variables';

import './editCustomAccountsDetails.scss';
import { logError } from '../../../../global/utils/logger';
import { useFilterState } from '../../../../cap';

const Duke = props => {
    const { handleStepChange, formStep } = props;
    const [
        { customBillingFields, punchoutUserData, userAccount, cart, consumables, clickedMakeChanges },
        dispatch
    ] = useCartState();
    const [{startDate, endDate}, filterDispatch] = useFilterState();
    const intl = useIntl();
    const [workOrderNumber, setWorkOrderNumber] = useState(customBillingFields?.wbsElement?.value || '');
    const [routeCode, setRouteCode] = useState(customBillingFields?.customerExtraField15?.value || '');
    const [codeBlock, setCodeBlock] = useState(customBillingFields?.customerExtraField16?.value || '');
    const [stormEmergency, setStormEmergency] = useState(customBillingFields?.customerExtraField17?.value || false);
    const [errorObj, setErrorObj] = useState({});
    const [isError, setIsError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { sendEventsForEcommerceCheckout, sendEventsForEcommerceCheckoutOption, sendEventsForUpdateVirtualPath } =
        useAnalyticsContext();
    const params = getURLParams();

    const callEvents = () => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() || clickedMakeChanges) {
            sendEventsForEcommerceCheckout(5, getProducts(cart, datesObj, false, consumables));
        }
    };

    useEffect(() => {
        callEvents();
    }, []);

    useEffect(() => {
        if (isSubmit && !isError) {
            dispatch({
                type: CREDIT_CUSTOM_ACCOUNT_INFO,
                customBillingFields: {
                    wbsElement: { value: workOrderNumber, number: 7 },
                    customerExtraField15: { value: routeCode, number: 15 },
                    customerExtraField16: { value: codeBlock, number: 16 },
                    customerExtraField17: { value: stormEmergency, number: 17 }
                }
            });
            handleStepChange(punchoutUserData?.isPunchoutUser ? formStep.TRANSFER_ORDER : formStep.SUBMIT_RESERVATION);
        }
    }, [isSubmit, isError]);

    const handleWorkOrderNumber = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, workOrderNumberError: '' });
        setWorkOrderNumber(text.target.value);
    };

    const handleRouteCode = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, routeCodeError: '' });
        setRouteCode(text.target.value);
    };

    const handleCodeBlock = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, codeBlockError: '' });
        setCodeBlock(text.target.value);
    };
    const handleSaveAndContinue = () => {
        const error = {};
        if (workOrderNumber && validateCustomField(workOrderNumber)) {
            error.workOrderNumberError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (routeCode && validateCustomField(routeCode)) {
            error.routeCodeError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (codeBlock && validateCustomField(codeBlock)) {
            error.codeBlockError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        setIsSubmit(true);
        try {
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                sendEventsForEcommerceCheckoutOption(5, {
                    accountName: userAccount?.accountName
                });
                sendEventsForUpdateVirtualPath(
                    `${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_ADDITIONAL_INFO}`,
                    `/${
                        localStorage.getItem('companyID') == 2
                            ? VARIABLE_CONFIG.REGION.CANADA
                            : VARIABLE_CONFIG.REGION.US
                    }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.CUSTOM_ACCOUNT_ADDITIONAL_INFO}`
                );
            }
        } catch (error) {
            logError(error, false, 'handleSaveAndContinue');
        }
    };
    const checkboxHandler = () => {
        setStormEmergency(!stormEmergency);
    };
    return (
        <>
            <h6 className="account-heading">{intl.formatMessage({ id: 'checkout:Duke-energy-heading' })}</h6>
            <p className="account-message">{intl.formatMessage({ id: 'checkout:Duke-energy-message' })}</p>
            <Input
                name={intl.formatMessage({ id: 'checkout:Duke-account-work-order-number' })}
                label={intl.formatMessage({ id: 'checkout:Duke-account-work-order-number' })}
                value={workOrderNumber}
                handleInputChange={handleWorkOrderNumber}
                placeholder={intl.formatMessage({ id: 'checkout:Duke-account-work-order-number-placeholder' })}
                maxLength="40"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Duke-account-work-order-number-placeholder' })}
                errorMsg={errorObj?.workOrderNumberError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Duke-account-route-code' })}
                label={intl.formatMessage({ id: 'checkout:Duke-account-route-code' })}
                value={routeCode}
                handleInputChange={handleRouteCode}
                placeholder={intl.formatMessage({ id: 'checkout:Duke-account-route-code-placeholder' })}
                maxLength="40"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Duke-account-route-code-placeholder' })}
                errorMsg={errorObj?.routeCodeError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Duke-account-code-block-number' })}
                label={intl.formatMessage({ id: 'checkout:Duke-account-code-block-number' })}
                value={codeBlock}
                handleInputChange={handleCodeBlock}
                placeholder={intl.formatMessage({ id: 'checkout:Duke-account-code-block-number-placeholder' })}
                maxLength="40"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Duke-account-code-block-number-placeholder' })}
                errorMsg={errorObj?.codeBlockError}
            />
            <div className="duke-checkbox-wrapper">
                <Checkbox
                    isChecked={stormEmergency}
                    field={intl.formatMessage({ id: 'checkout:Duke-account-checkbox' })}
                    label={intl.formatMessage({ id: 'checkout:Duke-account-checkbox' })}
                    onClick={checkboxHandler}
                />
            </div>
            <div className="saveAndContinueBtn">
                <Button
                    type="button"
                    data-testid={'btnID'}
                    onClick={handleSaveAndContinue}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    className="button button-primary button-block">
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        </>
    );
};
export default Duke;
