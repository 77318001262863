import React from 'react';
import { func, string } from 'prop-types';
import { Field } from '../../../../aem-core-components';

const TextArea = props => {
    const {
        label,
        name,
        handleChange,
        handleBlur,
        handleFocus,
        classes,
        value,
        errorMsg,
        placeholder,
        maxLength,
        dataTestId,
        lbltestid,
        fieldCustomClass,
        textAriaLabel
    } = props;

    return (
        <Field label={label} htmlFor={name} lbltestid={lbltestid} fieldCustomClass={fieldCustomClass} errorMsg={errorMsg}>
            <textarea
                className={`cmp-Field__field__input ${classes} ${errorMsg ? 'error_input__border' : ''}`}
                name={name}
                id={name}
                value={value}
                placeholder={placeholder}
                maxLength={maxLength}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                data-testid={dataTestId}
                autoComplete="off" 
                aria-label={textAriaLabel}/>
            {errorMsg ? <span className="error_input">{errorMsg}</span> : ''}
        </Field>
    );
};

TextArea.propTypes = {
    errorMsg: string,
    label: string,
    name: string,
    placeholder: string,
    value: string,
    handleChange: func,
    handleFocus: func,
    handleBlur: func,
    classes: string,
    fieldCustomClass: string
};

TextArea.defaultProps = {
    errorMsg: '',
    label: '',
    name: '',
    placeholder: '',
    value: '',
    classes: '',
    handleChange: () => {},
    handleBlur: () => {},
    handleFocus: () => {},
    fieldCustomClass: ''
};

export default TextArea;
