import moment from 'moment';

export const cardData = [
    {
        jobNumber: 1,
        content: "I'll pick it up"
    },
    { jobNumber: 2, content: 'Someone else will' }
];

export const obj1 = [
    {
        label: '9:00am',
        value: '9:00am'
    },
    {
        label: '10:00am',
        value: '10:00am'
    },
    {
        label: '11:00am',
        value: '11:00am'
    }
];

export const obj2 = [
    {
        label: '1:00pm',
        value: '1:00pm'
    },
    {
        label: '2:00pm',
        value: '2:00pm'
    },
    {
        label: '3:00pm',
        value: '4:00pm'
    }
];

export const getTimeWindowValue = (chooseTime, storeStartTime, storeCloseTime) => {
    const formattedChooseTime = moment(chooseTime, 'hh:mm');
    const formattedStoreStartTime = moment(storeStartTime, 'hh:mm');
    const formattedStoreCloseTime = moment(storeCloseTime, 'hh:mm');

    if (formattedChooseTime.isBefore(formattedStoreStartTime)) {
        return 1;
    } else if (formattedChooseTime.isAfter(formattedStoreCloseTime)) {
        return 2;
    } else {
        return 0;
    }
};

export const WITHIN_WINDOW = 0;
export const BEFORE_WINDOW = 1;
export const AFTER_WINDOW = 2;

export const crossIconSvg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEiSURBVHgB7ZVLbsIwFEWv0y6gw44rug9aBdbRHbRdAMIICZgBS2AXIPGdsQlYAywAGxvxiZDJtSEzfAZJlDznHfn52UAkEnl2XmhEq9rF98cQlRIwWS3gQ6fyiy8zJi29mzGjvNAEDK1+DnelJdrVOo23MTv0APEGrf9YuIcAGudnJmG/2ZhLfAMEXoLpemmmU0CI8lGo7CyHK3ltLNnvuYBltp7nStyZ3CIQQjOVSMSlBImQx4TynuThAi6JLIHJLX4lyHJdjgeSW3gXuHhNtNc7D8Jn4HrBnbjVHYUKuFa7xoK2aCECt1qNtWghAqzPH5DgAr6bjEsi/dwaiSVy4F0QssnYb9n9Xyt6eHkIqL65bszJ9u/V51kJhQEikUiEsAevLrT2X/135AAAAABJRU5ErkJggg=="
