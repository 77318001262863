import { array, func, number, string } from 'prop-types';
import React, { useState } from 'react';
import Button from '../../atoms/button';
import { useIntl } from 'react-intl';

const ViewAll = ({ items, renderItem, limit, viewAllClass, count, handleViewAll, containerClass }) => {
    const [isViewAll, setIsViewAll] = useState(limit && (count || items?.length) <= limit);

    const renderListMethod = () => {
        return items?.map((item, i) => {
            return <div key={i}>{((!isViewAll && i < limit) || isViewAll) && renderItem(item, i)}</div>;
        });
    };

    const onViewAllBtnClick = () => {
        setIsViewAll(true);
        handleViewAll && handleViewAll(true);
    };
    const intl = useIntl();
    return (
        <div className={containerClass}>
            <ul>{renderListMethod()}</ul>
            {!isViewAll && (
                <Button
                    type="button"
                    className={viewAllClass}
                    onClick={onViewAllBtnClick}
                    buttonAriaLabel={`${intl.formatMessage({ id: 'view-all-btn' })} (${count || items?.length})`}>
                    View all ({count || items?.length})
                </Button>
            )}
        </div>
    );
};
export default ViewAll;
ViewAll.propTypes = {
    items: array,
    renderItem: func,
    limit: number,
    viewAllClass: string,
    count: number,
    handleViewAll: func,
    containerClass: string
};
ViewAll.defaultProps = {
    containerClass: ''
};
