import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SET_HOW_TO_GET_YOUR_ORDER_FIELDS } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../contexts/cart';
import AlertIcon from '../../../../resources/images/alert-triangle-red.svg';
import useConsumables from '../../../../hooks/useConsumables';
import Alert from '../../../global/atoms/alert/alert';
import Button from '../../../global/atoms/button';
import Dropdown from '../../../global/atoms/dropdown';
import { CHECKOUT_AVAILABLE_TIME_ID, CHECKOUT_PICKUPTIME_ID } from '../../constants';
import { HOW_TO_GET_YOUR_ORDER } from '../../../../constants/cartConstants';
import { getRentalDuration } from '../../../global/utils/commonUtils';
import { getDifferenceinHours } from '../../../checkoutv2/utils/checkoutUtils';
import classes from './HowToGetOrderD2C.css';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { useSunbeltLocation } from '../../../../hooks/useSunbeltLocation';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { useDidMount } from '../../../../hooks/useDidMount';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import { logError } from '../../../global/utils/logger';
import { checkoutDatalocator } from '../dataLocators';
import { useFilterState } from '../../../cap';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

const HowToGetOrderD2C = props => {
    const [{ cart, howToGetYourOrderDetails, projectInfo, timeZoneID }, dispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }] = useFilterState();
    const {
        rentalStartDate,
        rentalEndDate,
        startDateSlots,
        endDateSlots,
        handleStepChange,
        formStep,
        checkoutErrorHandlingAnalytics
    } = props;
    const [chosenDeliveryTime, setChosenDeliveryTime] = useState(
        howToGetYourOrderDetails.selectedDeliveryTime.slot || ''
    );
    const [chosenPickUpTime, setChosenPickUpTime] = useState(howToGetYourOrderDetails.selectedPickUpTime.slot || '');
    const [isDeliveryError, setIsDeliveryError] = useState(false);
    const [isPickUpError, setIsPickUpError] = useState(false);
    const rentalAmount = cart?.estimatesResponse?.estimate?.totals?.rentalAmount || null;
    const [initialRentalAmount] = useState(rentalAmount);
    const [rentalRevisionAlert, setRentalRevisionAlert] = useState(false);
    const [{ getConsumables }] = useConsumables();
    const sunbeltLocation = useSunbeltLocation();
    const { sendEventsForUpdateVirtualPath, sendEventsForEcommerceCheckoutOption } = useAnalyticsContext();
    const intl = useIntl();
    const didMount = useDidMount();
    const userType = useCheckUser();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { deliveryPickUpCharges, salesTax } = orderEstimates?.totals || {};
    const currencyCode =
        localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US;
    const getDate = selectedDate => {
        const stringToDate = new Date(selectedDate);
        return moment(stringToDate).format('l');
    };

    // to show and hide the rentalAmount alert in case of updated amount
    useEffect(() => {
        if (didMount) {
            setRentalRevisionAlert(true);
        }
    }, [howToGetYourOrderDetails.estimatedTotal]);

    const setSelectedStoreDetailsValue = () => {
        try {
            let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

            if (overridePC?.pc) {
                return overridePC;
            } else {
                const storedValue = sessionStorage.getItem(
                    STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP
                );
                if (storedValue) {
                    return JSON.parse(storedValue) || {};
                } else {
                    return {};
                }
            }
        } catch (error) {
            logError(error, false, 'setSelectedStoreDetailsValue HTGOD2c');
            return {};
        }
    };

    const handleDeliveryTime = selectedValue => {
        setChosenDeliveryTime(selectedValue);
        setIsDeliveryError(false);
        setRentalRevisionAlert(false);
    };
    const handlePickUpTime = selectedValue => {
        setChosenPickUpTime(selectedValue);
        setIsPickUpError(false);
        setRentalRevisionAlert(false);
    };
    const sendEventsForDelivery = stepNumber => {
        try {
            sendEventsForEcommerceCheckoutOption(stepNumber, {
                deliveryDate: getDate(startDate),
                deliveryTime: JSON.stringify(howToGetYourOrderDetails?.selectedDeliveryTime?.label),
                returnDate: getDate(endDate),
                returnTime: VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED,
                rentalDuration: getRentalDuration(startDate, endDate),
                timeZone: timeZoneID,
                rentalSubtotal: rentalAmount,
                deliveryFee: deliveryPickUpCharges,
                deliverySurcharge: deliveryPickUpCharges,
                estimatedSubtotal: rentalAmount + deliveryPickUpCharges + salesTax,
                deliveryInstructions:
                    projectInfo?.accessNotes ||
                    projectDetails?.accessNotes ||
                    VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED
            });
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_DELIVERY_LOCATION,
                `/${currencyCode}/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.DELIVERY}`
            );
        } catch (error) {
            logError(error, false, 'sendEventsForDelivery');
        }
    };

    const handleSaveAndContinue = async () => {
        if (!chosenDeliveryTime) {
            setIsDeliveryError(true);
            checkoutErrorHandlingAnalytics(
                VARIABLE_CONFIG.CHECKOUT_ERROR_MESSAGES.EMPTY_DELIVERY_TIME,
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.DELIVERY_TIME
            ); // todo add error UI
        }
        if (!chosenPickUpTime && userType !== USER_TYPE.CREDIT) {
            setIsPickUpError(true);
            checkoutErrorHandlingAnalytics(
                VARIABLE_CONFIG.CHECKOUT_ERROR_MESSAGES.EMPTY_PICKUP_TIME,
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.PICK_UP_TIME
            ); // todo add error UI
        }
        if (userType !== USER_TYPE.CREDIT && chosenDeliveryTime !== '' && chosenPickUpTime !== '') {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                value: JSON.parse(
                    sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP)
                )
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_DELIVERY_TIME,
                value: {
                    slot: chosenDeliveryTime,
                    label: `${moment(chosenDeliveryTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenDeliveryTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SET_PICKUP_TIME,
                value: {
                    slot: chosenPickUpTime,
                    label: `${moment(chosenPickUpTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenPickUpTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
            await getConsumables();
            handleStepChange(formStep.HTGO + 1);
        }
        if (userType === USER_TYPE.CREDIT && chosenDeliveryTime !== '') {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                value: setSelectedStoreDetailsValue()
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_DELIVERY_TIME,
                value: {
                    slot: chosenDeliveryTime,
                    label: `${moment(chosenDeliveryTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenDeliveryTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
            await getConsumables(viewCart?.pc);
            handleStepChange(formStep.HTGO + 1);
        }

        try {
            sendEventsForDelivery(3);
        } catch (error) {
            logError(error, false, 'changeCUrrentStep');
        }
    };
    const makeSlots = (timeArr, date) => {
        let timeArr2 = [];
        timeArr?.forEach(time => {
            if (moment(time.start).format('MMM DD, YYYY') == date) {
                timeArr2.push({
                    value: `${time.start} - ${time.end}`,
                    label: `${moment(time.start).format('hh:mm A')} - ${moment(time.end).format('hh:mm A')}`
                });
            }
        });
        return timeArr2;
    };
    const getDeliveryTimeSlots = () => {
        return makeSlots(startDateSlots?.data?.data?.windows, rentalStartDate);
    };
    const getPickUpTimeSlots = () => {
        let temp1 = endDateSlots?.data?.data?.windows;
        let newTimeSlots1 = temp1?.filter(
            time => getDifferenceinHours(time.start, chosenDeliveryTime.split(' - ')[0]) >= 24
        );
        let newTimeSlots2 = makeSlots(newTimeSlots1, rentalEndDate);
        return newTimeSlots2;
    };

    return (
        <>
            <h6 className={classes.titleHtgo}>
                {userType === USER_TYPE.CREDIT
                    ? intl.formatMessage({ id: 'How-To-Get-Your-Order:deliveryHeader' })
                    : intl.formatMessage({ id: 'How-To-Get-Your-Order:deliveryAndPickupHeader' })}
            </h6>
            <div className={classes.driverText}>
                {intl.formatMessage({ id: 'How-To-Get-Your-Order:driver-drop-retrieve-text' })}
            </div>
            <div className={`${classes.dateInfo}  col-2-diff-mob`}>
                <div className={classes.rentalTimeInfo}>
                    <label htmlFor={CHECKOUT_AVAILABLE_TIME_ID}>
                        <span className={classes.timeLabel}>
                            {intl.formatMessage({ id: 'How-To-Get-Your-Order:EtimatedDeliveryTime' })}
                        </span>
                    </label>
                    <Dropdown
                        isError={isDeliveryError}
                        className={classes.dropDownCLass}
                        selectedState={chosenDeliveryTime}
                        onChangeDropdownValue={handleDeliveryTime}
                        options={getDeliveryTimeSlots()}
                        inputlabel={CHECKOUT_AVAILABLE_TIME_ID}
                        placeholderText={intl.formatMessage({ id: 'How-To-Get-Your-Order:Available-times' })}
                        dropDownTestId={checkoutDatalocator.checkout_delivery_time_dropdown}
                    />
                </div>
                {userType !== USER_TYPE.CREDIT ? (
                    <div className={classes.rentalTimeInfo}>
                        <label htmlFor={CHECKOUT_PICKUPTIME_ID}>
                            <span className={classes.timeLabel}>
                                {intl.formatMessage({ id: 'How-To-Get-Your-Order:PickupTime' })}
                            </span>
                        </label>
                        <Dropdown
                            isError={isPickUpError}
                            className={classes.dropDownCLass}
                            selectedState={chosenPickUpTime}
                            onChangeDropdownValue={handlePickUpTime}
                            options={getPickUpTimeSlots()}
                            inputlabel={CHECKOUT_PICKUPTIME_ID}
                            placeholderText={intl.formatMessage({ id: 'How-To-Get-Your-Order:Available-times' })}
                            dropDownTestId={checkoutDatalocator.checkout_pickup_time_dropdown}
                        />
                    </div>
                ) : null}
            </div>
            {userType === USER_TYPE.CREDIT ? (
                <>
                    <h6 className={classes.estimatedEndDate}>
                        {intl.formatMessage({ id: 'How-To-Get-Your-Order:PickupTime' })}
                    </h6>
                    <div className={classes.estimatedEndDateMessage}>
                        {intl.formatMessage({ id: 'How-To-Get-Your-Order:estimated-rentalEndDate-message' })}
                    </div>
                </>
            ) : null}
            {userType === USER_TYPE.CASH && rentalRevisionAlert && chosenDeliveryTime ? (
                <div className={classes.warningMessage}>
                    <Alert
                        className="alert"
                        type={'warning'}
                        message={intl.formatMessage({ id: 'How-To-Get-Your-Order:order-total-revision-warning' })}
                        useDefaultPadding={false}
                        icon={<AlertIcon tabIndex={-1} aria-hidden={true} />}
                    />
                </div>
            ) : null}
            <div className={classes.continueBtn}>
                <Button
                    type="button"
                    onClick={handleSaveAndContinue}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    className="button button-primary button-block"
                    data-testid={checkoutDatalocator.checkout_howtogetyourorderD2C_Continue_cta_testid}>
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        </>
    );
};
export default HowToGetOrderD2C;
