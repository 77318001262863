import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useCheckout from '../../../hooks/useCheckout';
import { useSunbeltLocation } from '../../../hooks/useSunbeltLocation';
import useComputeLocation from '../../../hooks/useComputeLocation';
import { logError } from '../../global/utils/logger';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { useFilterState } from '../../cap';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { ReadOnlyView } from '../../global/modules/stepForm';
import InstoreIcon from '../../../resources/images/instore.svg';
import Car from '../../../resources/images/car.svg';
import GetDirectionIcon from '../../../resources/images/get-direction.svg';
import ClockIcon from '../../../resources/images/clock.svg';
import './howToGetOrder.scss';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';

const ReadOnlyHowToGetOrder = props => {
    const intl = useIntl();
    const [{ projectDetails }] = useFilterState();
    const { clearCheckout } = useCheckout();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { sendEventsForClick } = useAnalyticsContext();
    const {
        handleStepChange,
        isInstore,
        storeDetails,
        startDate,
        endDate,
        deliveryTime,
        pickupTime,
        selectedStartTime,
        selectedEndTime,
        formStep
    } = props;
    const { storesCalls } = useComputeLocation();
    const sunbeltLocation = useSunbeltLocation();
    const userType = useCheckUser();
    const editHandler = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                `${intl.formatMessage({ id: 'checkout:how-to-get-your-order-title' }).toLowerCase()} edit`
            );
        } catch (error) {
            logError(error, false, 'editHandler');
        }
        setShowAlertModal(true);
    };

    const handleMakeChangesClick = () => {
        if (isInstore) {
            storesCalls();
        }
        setShowAlertModal(false);
        handleStepChange(formStep.HTGO);
        clearCheckout(formStep, formStep.HTGO);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
    };

    const renderReadOnlyBody = () => {
        return (
            <>
                {isInstore ? (
                    <div className="order-details-item">
                        <InstoreIcon />
                        <div className="order-details-desc">
                            <div className="order-details-title">
                                {intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            </div>
                            <span className="order-details-name">
                                {intl.formatMessage({ id: 'store-details:branch' })}
                                {storeDetails?.pc}
                            </span>
                            <span className="order-details-address text-capitalize">
                                {storeDetails?.street?.toLowerCase()}
                            </span>
                            <span className="order-details-address text-capitalize">
                                {storeDetails?.city?.toLowerCase()}, {storeDetails?.state} {storeDetails?.zip}{' '}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="order-details-item">
                        <Car />
                        <div className="order-details-desc">
                            <div className="order-details-title">
                                {intl.formatMessage({ id: 'cart:round-trip-heading-pcoverride' })}
                            </div>
                            <span className="order-details-address">{projectDetails?.projectName}</span>
                            <span className="order-details-address">{projectDetails?.projectAddress1}</span>
                            <span className="order-details-address">
                                {projectDetails?.selectedProjectCity} {projectDetails?.selectedProjectState}{' '}
                                {projectDetails?.selectedProjectZip}{' '}
                            </span>
                        </div>
                    </div>
                )}
                <div className="order-details-item">
                    <ClockIcon />
                    <div className="order-details-desc">
                        <div className="order-details-title">
                            {isInstore
                                ? intl.formatMessage({ id: 'How-To-Get-Your-Order:PickupTime' })
                                : intl.formatMessage({ id: 'How-To-Get-Your-Order:EtimatedDeliveryTime-text' })}
                        </div>
                        {isInstore ? (
                            <div>
                                {startDate} at {selectedStartTime}
                            </div>
                        ) : (
                            <div>
                                {startDate} at {deliveryTime}
                            </div>
                        )}
                        {userType === USER_TYPE.CREDIT && !isInstore ? null : (
                            <>
                                <div className="order-details-title return-time">
                                    {' '}
                                    {isInstore
                                        ? intl.formatMessage({ id: 'checkout:HTGO-return-time' })
                                        : intl.formatMessage({ id: 'checkout:HTGO-pickup-time' })}
                                </div>
                                {isInstore ? (
                                    <div>
                                        {endDate} at {selectedEndTime}
                                    </div>
                                ) : (
                                    <div>
                                        {endDate}, {pickupTime}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {userType === USER_TYPE.CREDIT && isInstore && (
                    <div className="order-details-item">
                        <GetDirectionIcon className="icon-color" />
                        <div className="order-details-desc">
                            <div className="order-details-title">
                                {intl.formatMessage({ id: 'checkout:read-only-jobsite-details' })}
                            </div>
                            <span className="order-details-address">{projectDetails?.projectName}</span>
                            <span className="order-details-address">{projectDetails?.projectAddress1}</span>
                            <span className="order-details-address">
                                {projectDetails?.selectedProjectCity} {projectDetails?.selectedProjectState}{' '}
                                {projectDetails?.selectedProjectZip}{' '}
                            </span>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <ReadOnlyView
                children={renderReadOnlyBody()}
                title={intl.formatMessage({ id: 'checkout:how-to-get-your-order-title' })}
                testId={checkoutDatalocator.checkout_howtogetyourorder_link_testid}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};

export default ReadOnlyHowToGetOrder;
