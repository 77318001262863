import React from 'react';

import { VARIABLE_CONFIG } from './Variables';

export const triggerEvents = (eventAction,eventLabel,sendEventsForClick) => {
    sendEventsForClick(
        VARIABLE_CONFIG.EVENT.UAEVENT,
        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
        VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
        eventAction.toLowerCase(),
        eventLabel.toLowerCase()
    ); 
}
