import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useCookieValue } from '../../../../aem-core-components/utils/hooks';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { checkoutDatalocator } from '../../../checkoutv2/checkoutAndOrderSummary/dataLocators';
import TransferOrderConfirmationScreen from '../../../global/modules/TransferOrderConfirmationScreen/TransferOrderConfirmationScreen';
import useReservation from '../../hooks/useReservation';
import { redirectToQuotesPageWithQuoteId } from '../../../search/utils/searchUtils';
import './quoteConfirmation.scss';

const SaveQuoteConfirmation = ({ confirmationType }) => {
    const intl = useIntl();
    const [{ resetDetailsAfterQuoteSaveOrTransmit }] = useReservation();
    const [punchoutRedirectURL] = useCookieValue('punchout_redirect_url');

    useEffect(() => {
        resetDetailsAfterQuoteSaveOrTransmit();
    }, []);

    const getConfirmationTitle = () => {
        const formattedConfirmationType = confirmationType?.replace(/'/g, '').toLowerCase();
        return intl.formatMessage({ id: `createQuote:save-for-later-${formattedConfirmationType}` });
    };

    const renderDetail = () => {
        return (
            <div className="details_container" tabIndex={0}>
                <span>{intl.formatMessage({ id: 'createQuote:save-for-later-confirmation-message' })}</span>
            </div>
        );
    };

    const redirectToQuotesPage = () => {
        const quoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
        redirectToQuotesPageWithQuoteId(quoteId);
    };

    const redirectToTCportal = () => {
        const redirectURL = punchoutRedirectURL;
        window.parent.location.href = redirectURL;
    };

    return (
        <>
            <TransferOrderConfirmationScreen
                confirmationType={confirmationType}
                handlePrimaryButton={redirectToQuotesPage}
                handleSecondaryButton={redirectToTCportal}
                renderDetail={renderDetail}
                primaryButtonLabel={intl.formatMessage({ id: 'createQuote:save-for-later-view-saved-quote-cta' })}
                secondaryButtonLabel={intl.formatMessage({
                    id: 'createQuote:transmit-back-to-procurement-system-cta'
                })}
                confirmationTitle={getConfirmationTitle()}
                primaryButtonDataTestId={checkoutDatalocator.reservation_view_rentals_cta_testid}
                secondaryButtonDataTestId={checkoutDatalocator.reservation_continue_exploring_cta_testid}
            />
        </>
    );
};

export default SaveQuoteConfirmation;
