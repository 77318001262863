    import React, { useState } from 'react';
    import { useCartState } from '../../../../contexts/cart';
    import { useUserContext } from '../../../../aem-core-components/context/UserContext';
    import { CollapsedView } from '../../../global/modules/stepForm';
    import classes from '../../checkoutAndOrderSummary/checkoutAndOrderSummary.css';
    import { checkoutDatalocator } from '../dataLocators';
    import EditCustomAccountDetails from './editCustomAccountDetails/EditCustomAccountDetails.js';
    import ReadOnlyCustomAccountsDetails from './ReadOnlyCustomAccountsDetails';

    const CustomAccountDetails = props => {
        const { currentStep, handleStepChange, formStep, customBillingFields: customBillingFieldsFromProps } = props;
        const [{ customBillingFields, userAccount }] = useCartState();
        const [{ userProfile }] = useUserContext();
        const accountNumber = userAccount?.accountNumber;
        const corpLink = userProfile?.accounts?.find(account => account?.id === accountNumber)?.corplink;
        const [formState, setFormState] = useState(customBillingFields || {});
        const stepNumber = formStep.CUSTOM_ACCOUNT_INFO;

        return (
            <div className={`${classes.root} checkout__last`}>
                {currentStep < stepNumber && (
                    <CollapsedView
                        stepNumber={formStep.CUSTOM_ACCOUNT_INFO}
                        stepTitle={'Custom Account Details'}
                        testId={checkoutDatalocator.checkout_payment_link_testid}
                    />
                )}
                {currentStep === stepNumber && (
                    <EditCustomAccountDetails
                        currentStep={currentStep}
                        handleStepChange={handleStepChange}
                        formStep={formStep}
                        formState={formState}
                        onFormStateChange={setFormState}
                        customBillingFields={customBillingFieldsFromProps}
                        corpLink={corpLink}
                    />
                )}
                {currentStep > stepNumber && (
                    <ReadOnlyCustomAccountsDetails
                        currentStep={currentStep}
                        handleStepChange={handleStepChange}
                        formStep={formStep}
                        customBillingFields={customBillingFieldsFromProps}
                        corpLink={corpLink}
                    />
                )}
            </div>
        );
    };
    export default CustomAccountDetails;
