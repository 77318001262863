import React from 'react';
import { func, string, node } from 'prop-types';
import EditButton from '../../atoms/editButton';
import './readOnlyView.scss';
import CheckMarkIcon from '../../../../resources/images/checkmark.svg';

const ReadOnlyView = props => {
    const { children, title, testId = '', onEdit } = props;
    return (
        <div aria-label={title} className="readonly-details">
            <div className="readonly-details-header">
                <div className="readonly-details-headerleft">
                    <span className="readonly-details-header-icon">
                        <CheckMarkIcon />
                    </span>
                    <h6>{title}</h6>
                </div>
                <EditButton handleOnClick={onEdit} testId={testId} customButtonAriaLabel={title} />
            </div>
            {children && <div className="readonly-details-body">{children}</div>}
        </div>
    );
};

ReadOnlyView.propTypes = {
    children: node,
    title: string,
    testId: string,
    onEdit: func
};

ReadOnlyView.defaultProps = {
    children: null,
    title: '',
    testId: '',
    onEdit: () => {}
};

export default ReadOnlyView;
