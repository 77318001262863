import React, { useState } from 'react';
import { func, number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import CounterButton from '../../../global/atoms/CounterButton';
import { Price } from '../../../../aem-core-components';
import {
    SET_CURRENT_ACCESSORIES_AVAILABLE_QUANTITY,
    SET_CURRENT_ADDONS_AVAILABLE_QUANTITY
} from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../contexts/cart';
import '../../howToGetYourOrder/howToGetOrder.scss';
import { INCREMENT } from '../../../global/constants';

const AddItemCard = ({
    title,
    type,
    price,
    handleValueChange,
    currencyCode,
    selectedItems,
    sku,
    rentalClass,
    remainingQuantity,
    isAddOns,
    maxQuantityLimit,
    availableQuantity,
    itemObj
}) => {
    const [itemInfo, setItemInfo] = useState({
        name: title,
        qty: selectedItems[sku]?.qty || 0,
        price: price,
        rentalClass,
        itemObj
    });
    const [isMaxQuantityReached, setIsMaxQuantityReached] = useState(false);
    const [, dispatch] = useCartState();
    const intl = useIntl();
    const updateItem = (val, action) => {
        setItemInfo({ ...itemInfo, qty: val });
        handleValueChange(sku, { ...itemInfo, qty: val });
        if (isAddOns) {
            dispatch({
                type: SET_CURRENT_ADDONS_AVAILABLE_QUANTITY,
                key: sku,
                value: action === INCREMENT ? remainingQuantity - 1 : remainingQuantity + 1
            });
        } else {
            dispatch({
                type: SET_CURRENT_ACCESSORIES_AVAILABLE_QUANTITY,
                key: sku,
                value: action === INCREMENT ? remainingQuantity - 1 : remainingQuantity + 1
            });
        }
    };

    const updateInputVal = val => {
        const inputQty = val > 0 ? val : 0;
        setItemInfo({ ...itemInfo, qty: inputQty });
        handleValueChange(sku, { ...itemInfo, qty: inputQty });
        if (isAddOns) {
            dispatch({
                type: SET_CURRENT_ADDONS_AVAILABLE_QUANTITY,
                key: sku,
                value: availableQuantity - inputQty
            });
        } else {
            dispatch({
                type: SET_CURRENT_ACCESSORIES_AVAILABLE_QUANTITY,
                key: sku,
                value: availableQuantity - inputQty
            });
        }
    };
    return (
        <div className="addItemCardContainer">
            <span className="itemTitle">{title}</span>
            <div className="itemDesc">
                <div className="itemPriceContainer">
                    <span className="itemPriceType">{type}</span>
                    <Price
                        currencyCode={currencyCode}
                        value={itemInfo?.qty === 0 ? price : itemInfo?.qty * price}
                        className="itemPrice"
                    />
                </div>
                <div className="addToOrderButtonContainer">
                    <CounterButton
                        value={itemInfo?.qty}
                        handleValueChange={updateItem}
                        handleInputChange={updateInputVal}
                        maxLimit={remainingQuantity + itemInfo?.qty}
                        maxQuantityLimit={maxQuantityLimit}
                        setIsMaxQuantityReached={setIsMaxQuantityReached}
                    />
                    
                </div>
            </div>
            {isMaxQuantityReached && (
                <span className="itemMaxQuantityAlert">{`${intl.formatMessage({
                    id: 'checkout:accessories-max-quantity-error-prefix'
                })} (${
                    remainingQuantity + itemInfo?.qty <= maxQuantityLimit ? availableQuantity : maxQuantityLimit
                }) ${intl.formatMessage({
                    id: 'checkout:accessories-max-quantity-error-suffix'
                })}`}</span>
            )}
        </div>
    );
};

export default AddItemCard;

AddItemCard.propsType = {
    title: string,
    type: string,
    price: number,
    value: string,
    currencyCode: string,
    handleValueChange: func
};

AddItemCard.defaultProps = {
    title: '',
    type: '',
    price: 0,
    value: 0,
    currencyCode: 'USD',
    handleValueChange: () => {}
};
