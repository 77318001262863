import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../contexts/cart';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { getProducts } from '../../../../constants/analyticsConstants/getProducts';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import Input from '../../../global/atoms/input/Input';
import TextArea from '../../../global/atoms/textArea';
import Name from '../../../global/modules/formFields/name';
import PhoneNumber from '../../../global/modules/formFields/phoneNumber';
import Button from '../../../global/atoms/button/button';
import { EditableView } from '../../../global/modules/stepForm';
import { checkValidAccessNotes, checkValidPONumber } from '../../../checkoutv2/createJobsite/utils/validateFunctions';
import { EDIT_PROJECT_INFO } from '../../../../aem-core-components/actions/constants';
import './editProjectInfo.scss';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { logError } from '../../../global/utils/logger';
import { getURLParams, isPaymentSectionVisited } from '../../../global/utils/commonUtils';
import useCheckout from '../../../../hooks/useCheckout';
import { useFilterState } from '../../../cap';

const EditProjectInfo = props => {
    const {
        currentStep,
        handleStepChange,
        formStep,
        isCustomAccount,
        primaryContactName,
        phoneNumber,
        accessNotes,
        poNumber,
        setPrimaryContactName,
        setPhoneNumber,
        setAccessNotes,
        setPoNumber
    } = props;
    const [
        { cart, userAccount, punchoutUserData, consumables, clickedMakeChanges },
        dispatch
    ] = useCartState();
    const [{viewCart, projectDetails, startDate, endDate}, filterDispatch] = useFilterState();
    const { isInStorePickup } = viewCart;
    const [validPrimaryContactName, setValidPrimaryContactName] = useState(false);
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [accessNotesError, setAccessNotesError] = useState('');
    const [poNumberError, setPONumberError] = useState('');
    const [validAccessNotes, setValidAccessNotes] = useState(false);
    const [validPONumber, setValidPONumber] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [required, setRequired] = useState(true);
    const intl = useIntl();
    const nextFormStep = (punchoutUserData?.isPunchoutUser && formStep.TRANSFER_ORDER) || formStep.SUBMIT_RESERVATION;
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { sendEventsForUpdateVirtualPath, sendEventsForEcommerceCheckoutOption, sendEventsForEcommerceCheckout } =
        useAnalyticsContext();
    const { checkoutErrorAnalyticsEvent } = useCheckout();

    const params = getURLParams();

    const callEvents = () => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() || clickedMakeChanges) {
            sendEventsForEcommerceCheckout(4, getProducts(cart, datesObj, false, consumables));
        }
    };

    useEffect(() => {
        callEvents();
    }, []);

    useEffect(() => {
        if (submit) {
            if (!isInStorePickup) {
                if ((accessNotes && !validAccessNotes) || (poNumber && !validPONumber)) {
                    setSubmit(false);
                    return;
                }
                if (validPrimaryContactName && validPhoneNumber) {
                    dispatch({
                        type: EDIT_PROJECT_INFO,
                        primaryContactName: primaryContactName,
                        phoneNumber: phoneNumber,
                        accessNotes: accessNotes?.trim(),
                        poNumber: poNumber
                    });
                    changeFormStep();
                }
            } else if (isInStorePickup) {
                if (poNumber && !validPONumber) {
                    setSubmit(false);
                    return;
                }
                dispatch({
                    type: EDIT_PROJECT_INFO,
                    primaryContactName: primaryContactName,
                    phoneNumber: phoneNumber,
                    accessNotes: accessNotes?.trim(),
                    poNumber: poNumber
                });
                changeFormStep();
            }
        }
    }, [validPrimaryContactName, validPhoneNumber, isInStorePickup, validAccessNotes, validPONumber, submit]);

    const changeFormStep = () => {
        if (isCustomAccount) {
            handleStepChange(formStep.CUSTOM_ACCOUNT_INFO);
        } else {
            handleStepChange(nextFormStep);
        }
    };

    const handlePrimaryContactName = text => {
        setPrimaryContactName(text.target.value);
    };

    const handlePhoneNumberChange = text => {
        setPhoneNumber(text.target.value);
    };

    const handleAccessNotesChange = text => {
        setAccessNotes(text.target.value);
        setAccessNotesError('');
    };

    const handlePONumberChange = text => {
        setPoNumber(text.target.value);
        setPONumberError('');
    };

    const onErrorAnalyticsEvent = (errorMessage, fieldName) => {
        checkoutErrorAnalyticsEvent(formStep, currentStep, errorMessage, fieldName);
    };

    const handleSaveAndContinue = () => {
        setSubmit(true);
        try {
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                sendEventsForEcommerceCheckoutOption(4, {
                    accountName: userAccount?.accountName
                });
                sendEventsForUpdateVirtualPath(
                    VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_PROJECT_INFO,
                    `/${
                        localStorage.getItem('companyID') == 2
                            ? VARIABLE_CONFIG.REGION.CANADA
                            : VARIABLE_CONFIG.REGION.US
                    }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.CUSTOM_ACCOUNT_PROJECT}`
                );
            }
        } catch (error) {
            logError(error, false, 'handleSaveAndContinue');
        }

        if (accessNotes) {
            setValidAccessNotes(checkValidAccessNotes(accessNotes, setAccessNotesError, intl, onErrorAnalyticsEvent));
        }
        if (poNumber) {
            setValidPONumber(checkValidPONumber(poNumber, setPONumberError, intl, onErrorAnalyticsEvent));
        }
    };
    const onValidatePrimaryContactName = isValid => {
        setValidPrimaryContactName(isValid);
    };
    const onValidatePhoneNumber = isValid => {
        setValidPhoneNumber(isValid);
    };
    const createOnErrorAnalyticsEvent = (errorMsg, field) => () => {
        onErrorAnalyticsEvent(errorMsg, field);
    };

    const renderEditableView = () => {
        return (
            <div className="projectInfoBody">
                {!isInStorePickup && (
                    <>
                        <Name
                            name={'primaryContact'}
                            label={intl.formatMessage({ id: 'create-project:primary-contact-label' })}
                            value={primaryContactName}
                            handleInputChange={handlePrimaryContactName}
                            onValidate={onValidatePrimaryContactName}
                            submit={submit}
                            setSubmit={setSubmit}
                            required={required}
                            inputAriaLabel={intl.formatMessage({ id: 'checkout:your-details-first-name-placeholder' })}
                            placeholder={intl.formatMessage({ id: 'project-info:primary-contact-placeholder' })}
                            requiredErrorMsg={intl.formatMessage({ id: 'project-info:primary-contact-error' })}
                            formatErrorMsg={intl.formatMessage({ id: 'project-info:primary-contact-format-error' })}
                            onErrorAnalytics={createOnErrorAnalyticsEvent(
                                intl.formatMessage({ id: 'project-info:primary-contact-format-error' }),
                                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.PRIMARY_CONTACT_NAME
                            )}
                        />
                        <PhoneNumber
                            value={phoneNumber}
                            handleInputChange={handlePhoneNumberChange}
                            onValidate={onValidatePhoneNumber}
                            submit={submit}
                            setSubmit={setSubmit}
                            required={required}
                            inputAriaLabel={intl.formatMessage({
                                id: 'checkout:your-details-phone-number-placeholder'
                            })}
                            placeholder={intl.formatMessage({ id: 'project-info:primary-phone-placeholder' })}
                            onErrorAnalytics={createOnErrorAnalyticsEvent(
                                intl.formatMessage({ id: 'common:required-input-error' }),
                                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.PHONE_NUMBER
                            )}
                        />
                        <TextArea
                            label={intl.formatMessage({ id: 'project-info:access-notes-label' })}
                            value={accessNotes}
                            handleChange={handleAccessNotesChange}
                            placeholder={intl.formatMessage({ id: 'create-project:access-notes-placeholder' })}
                            classes="accessNotesTextAreaWithMargin"
                            fieldCustomClass="createProjectTextarea"
                            maxLength="160"
                            textAriaLabel={intl.formatMessage({ id: 'project-info:access-notes-label' })}
                            errorMsg={accessNotesError}
                        />
                    </>
                )}
                {!punchoutUserData?.isPunchoutUser && (
                    <Input
                        name="poNumber"
                        label={intl.formatMessage({ id: 'project-info:po-number' })}
                        value={poNumber}
                        handleInputChange={handlePONumberChange}
                        placeholder={intl.formatMessage({ id: 'create-project:po-number-placeholder' })}
                        maxLength="26"
                        inputAriaLabel={intl.formatMessage({ id: 'project-info:po-number' })}
                        errorMsg={poNumberError}
                    />
                )}
                <Button
                    type="button"
                    data-testid={'btnID'}
                    onClick={handleSaveAndContinue}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    className="button button-primary button-block save_continue-button">
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        );
    };
    return (
        <EditableView
            children={renderEditableView()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:project-info' })}
        />
    );
};
export default EditProjectInfo;
