import React from 'react';
import { useIntl } from 'react-intl';
import isObjectEmpty from '../../../../../aem-core-components/utils/isObjectEmpty';
import { EditableView } from '../../../../global/modules/stepForm';
import { DUKE_CORP_LINK } from '../../../constants';
import CustomFormFields from './CustomFormFields';
import Disney from './Disney';
import Duke from './Duke';

const EditCustomAccountDetails = props => {
    const { currentStep, handleStepChange, formStep, onFormStateChange, formState, customBillingFields, corpLink } =
        props;
    const isDisney = !isObjectEmpty(customBillingFields)
        ? JSON.parse(customBillingFields?.isDisneyAccount?.toLowerCase())
        : false;
    const isDuke = corpLink === DUKE_CORP_LINK ? true : false;
    const intl = useIntl();
    const sectionTitle = isDisney
        ? intl.formatMessage({ id: 'checkout:Disney-title' })
        : isDuke
        ? intl.formatMessage({ id: 'checkout:Duke-energy-title' })
        : intl.formatMessage({ id: 'checkout:custom-field-title' });

    const renderEditableView = () => {
        return (
            <>
                {isDisney && (
                    <Disney currentStep={currentStep} handleStepChange={handleStepChange} formStep={formStep} />
                )}
                {!isDisney && isDuke && (
                    <Duke currentStep={currentStep} handleStepChange={handleStepChange} formStep={formStep} />
                )}
                {!isDisney && !isDuke && (
                    <CustomFormFields
                        currentStep={currentStep}
                        handleStepChange={handleStepChange}
                        formStep={formStep}
                        formState={formState}
                        onFormStateChange={onFormStateChange}
                        customBillingFields={customBillingFields?.customBillingFields}
                    />
                )}
            </>
        );
    };
    return <EditableView children={renderEditableView()} currentStep={currentStep} currentTitle={sectionTitle} />;
};
export default EditCustomAccountDetails;
