import React from 'react';
import { func, number, object } from 'prop-types';
import EditYourDetails from './EditYourDetails';
import ReadOnlyYourDetails from './ReadOnlyYourDetails';
import './yourDetails.scss';

const YourDetails = props => {
    const { currentStep, handleStepChange, formStep } = props;
    const stepNumber = formStep.YOUR_DETAILS;

    return (
        <div className={'howToGetOrder'}>
            {currentStep === stepNumber && (
                <EditYourDetails currentStep={currentStep} handleStepChange={handleStepChange} formStep={formStep} />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyYourDetails handleStepChange={handleStepChange} formStep={formStep} />
            )}
        </div>
    );
};

export default YourDetails;

YourDetails.propTypes = {
    currentStep: number,
    handleStepChange: func,
    formStep: object
};
