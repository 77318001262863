import React from 'react';
import { useIntl } from 'react-intl';
import paths from '../../App/config';
import Button from '../../global/atoms/button/button';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { triggerEvents } from '../../../constants/analyticsConstants/triggerEvents';
import { logError } from '../../global/utils/logger';
export default function ReservationContinueButton() {
    const intl = useIntl();
    const userType = useCheckUser();
    const { sendEventsForClick } = useAnalyticsContext();
    const handleViewRentalClick = () => {
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'reservation:view-rental-cta' }),
                sendEventsForClick
            );
        } catch (error) {
            logError(error, false, 'handleViewRentalClick');
        }
        window.location = paths?.pagePaths?.sbrMyAccount;
    };
    const handleContinueClick = () => {
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'reservation:continue-cta' }),
                sendEventsForClick
            );
        } catch (error) {
            logError(error, false, 'handleContinueClick');
        }
        window.location = paths?.pagePaths?.homePage;
    };
    return (
        <div className="reservation-button-container">
            <Button
                className={`button button-primary button-block`}
                type="button"
                buttonAriaLabel={intl.formatMessage({ id: 'reservation:view-rental-cta' })}
                onClick={handleViewRentalClick}
                data-testid={checkoutDatalocator.reservation_view_rentals_cta_testid}>
                {intl.formatMessage({ id: 'reservation:view-rental-cta' })}
            </Button>
            <Button
                className={'button button-outline-primary'}
                type="button"
                buttonAriaLabel={intl.formatMessage({ id: 'reservation:continue-cta' })}
                onClick={handleContinueClick}
                data-testid={checkoutDatalocator.reservation_continue_exploring_cta_testid}>
                {intl.formatMessage({ id: 'reservation:continue-cta' })}
            </Button>
        </div>
    );
}
