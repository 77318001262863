import React from 'react';
import PropTypes from 'prop-types';
import { SUCCESS } from '../../../global/constants';
import SaveQuoteConfirmation from './SaveQuoteConfirmation';
import TransmitConfirmation from './TransmitConfirmation';

const QuoteConfirmation = ({ isTransmit, confirmationType, transmittedQuoteId, salesforceQuoteId }) => {
    return (
        <>
            {isTransmit ? (
                <TransmitConfirmation confirmationType={confirmationType} transmittedQuoteId={transmittedQuoteId} salesforceQuoteId={salesforceQuoteId} />
            ) : (
                <SaveQuoteConfirmation confirmationType={confirmationType} />
            )}
        </>
    );
};

export default React.memo(QuoteConfirmation);

QuoteConfirmation.propTypes = {
    isTransmit: PropTypes.bool,
    confirmationType: PropTypes.string,
    transmittedQuoteId: PropTypes.string
};

QuoteConfirmation.defaultProps = {
    isTransmit: false,
    confirmationType: SUCCESS,
    transmittedQuoteId: ''
};
