import React, { useEffect, useState } from 'react';
import Button from '../../../../global/atoms/button/button';
import { useIntl } from 'react-intl';
import Dropdown from '../../../../global/atoms/dropdown/dropdown';
import Input from '../../../../global/atoms/input/Input';
import { Field } from '../../../../../aem-core-components';
import { getURLParams, isPaymentSectionVisited, validateCustomField } from '../../../../global/utils/commonUtils';
import { MakeArrayOfObjects } from '../../../utils/checkoutUtils';
import { useUserContext } from '../../../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../../../contexts/cart';
import { useAnalyticsContext } from '../../../../../config/GoogleTagManagerEvents';
import { getProducts } from '../../../../../constants/analyticsConstants/getProducts';
import { CREDIT_CUSTOM_ACCOUNT_INFO } from '../../../../../aem-core-components/actions/constants';
import { VARIABLE_CONFIG } from '../../../../../constants/analyticsConstants/Variables';
import './editCustomAccountsDetails.scss';
import { logError } from '../../../../global/utils/logger';
import { useFilterState } from '../../../../cap';

const Disney = props => {
    const { handleStepChange, formStep } = props;
    const intl = useIntl();
    const [
        { customBillingFields, punchoutUserData, cart, consumables, userAccount, clickedMakeChanges },
        dispatch
    ] = useCartState();
    const [{ startDate, endDate}, filterDispatch] = useFilterState();

    const [companyCode, setCompanyCode] = useState(customBillingFields?.companyCode?.value || '');
    const [companyArea, setCompanyArea] = useState(customBillingFields?.companyArea?.value || '');
    const [costCenter, setCostCenter] = useState(customBillingFields?.costCenter?.value || '');
    const [profitCenter, setProfitCenter] = useState(customBillingFields?.profitCenter?.value || '');
    const [perner, setPERNER] = useState(customBillingFields?.perner?.value || '');
    const [distributionChannel, setDistributionChannel] = useState(
        customBillingFields?.distributionChannel?.value || ''
    );
    const [wbsElement, setWBSElement] = useState(customBillingFields?.wbsElement?.value || '');
    const [jobNumber, setJobNumber] = useState(customBillingFields?.jobNumber?.value || '');
    const [BVCC, setBVCC] = useState(customBillingFields?.customerExtraField15?.value || '');
    const [eventName, setEventName] = useState(customBillingFields?.eventName?.value || '');
    const [miscellaneous, setMiscellaneous] = useState(customBillingFields?.customerExtraField14?.value || '');
    const [orderedFor, setOrderedFor] = useState(customBillingFields?.orderedFor?.value || '');
    const [approvedBy, setApprovedBy] = useState(customBillingFields?.approvedBy?.value || '');
    const [glAccount, setGLAccount] = useState(customBillingFields?.glAccount?.value || '');
    const [businessArea, setBusinessArea] = useState(customBillingFields?.businessArea?.value || '');
    const [errorObj, setErrorObj] = useState({});
    const GLAccountArray = [
        '',
        '678001 Equipment Rentals',
        '683001 Equipment Maintenance',
        '685001 Supplies – Merchandise'
    ];
    const objGLAccount = MakeArrayOfObjects(GLAccountArray);
    const BusinessAreaArray = ['', '138', '139', '140'];
    const objBusinessArea = MakeArrayOfObjects(BusinessAreaArray);

    const [isError, setIsError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { sendEventsForEcommerceCheckout, sendEventsForEcommerceCheckoutOption, sendEventsForUpdateVirtualPath } =
        useAnalyticsContext();
    const params = getURLParams();

    const callEvents = () => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() || clickedMakeChanges) {
            sendEventsForEcommerceCheckout(5, getProducts(cart, datesObj, false, consumables));
        }
    };

    useEffect(() => {
        callEvents();
    }, []);

    useEffect(() => {
        if (isSubmit && !isError) {
            dispatch({
                type: CREDIT_CUSTOM_ACCOUNT_INFO,
                customBillingFields: {
                    companyCode: { value: companyCode, number: 1 },
                    glAccount: { value: glAccount, number: 2 },
                    businessArea: { value: businessArea, number: 3 },
                    companyArea: { value: companyArea, number: 4 },
                    costCenter: { value: costCenter, number: 5 },
                    profitCenter: { value: profitCenter, number: 6 },
                    wbsElement: { value: wbsElement, number: 7 },
                    jobNumber: { value: jobNumber, number: 8 },
                    perner: { value: perner, number: 9 },
                    distributionChannel: { value: distributionChannel, number: 10 },
                    orderedFor: { value: orderedFor, number: 11 },
                    approvedBy: { value: approvedBy, number: 12 },
                    eventName: { value: eventName, number: 13 },
                    customerExtraField14: { value: miscellaneous, number: 14 },
                    customerExtraField15: { value: BVCC, number: 15 }
                }
            });
            handleStepChange(punchoutUserData?.isPunchoutUser ? formStep.TRANSFER_ORDER : formStep.SUBMIT_RESERVATION);
        }
    }, [isSubmit, isError]);

    const handleCompanyCode = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, companyCodeError: '' });
        setCompanyCode(text.target.value);
    };
    const handleCOArea = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, COAreaError: '' });
        setCompanyArea(text.target.value);
    };
    const handleCostCenter = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, costCenterError: '' });
        setCostCenter(text.target.value);
    };
    const handleProfitCenter = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, profitCenterError: '' });
        setProfitCenter(text.target.value);
    };
    const handlePERNER = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, PERNERError: '' });
        setPERNER(text.target.value);
    };
    const handleDistributionChannel = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, distributionChannelError: '' });
        setDistributionChannel(text.target.value);
    };
    const handleWBS = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, wbsElementError: '' });
        setWBSElement(text.target.value);
    };
    const handleJNumber = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, JnumberError: '' });
        setJobNumber(text.target.value);
    };
    const handleBVCC = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, BVCCError: '' });
        setBVCC(text.target.value);
    };

    const handleEventName = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, eventNameError: '' });
        setEventName(text.target.value);
    };
    const handleMiscellaneous = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, miscellaneousError: '' });
        setMiscellaneous(text.target.value);
    };
    const handleReceiverFirst = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, receiverFirstError: '' });
        setOrderedFor(text.target.value);
    };
    const handleApproverFirst = text => {
        setIsSubmit(false);
        setIsError(false);
        setErrorObj({ ...errorObj, approverFirstError: '' });
        setApprovedBy(text.target.value);
    };

    const handleGLAccountOption = value => {
        setGLAccount(value);
    };
    const handleBusinessAreaOption = value => {
        setBusinessArea(value);
    };

    const handleSaveAndContinue = () => {
        const error = {};
        if (companyCode && validateCustomField(companyCode)) {
            error.companyCodeError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (costCenter && validateCustomField(costCenter)) {
            error.costCenterError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (perner && validateCustomField(perner)) {
            error.PERNERError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (wbsElement && validateCustomField(wbsElement)) {
            error.wbsElementError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (BVCC && validateCustomField(BVCC)) {
            error.BVCCError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (eventName && validateCustomField(eventName)) {
            error.eventNameError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (orderedFor && validateCustomField(orderedFor)) {
            error.receiverFirstError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (approvedBy && validateCustomField(approvedBy)) {
            error.approverFirstError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (miscellaneous && validateCustomField(miscellaneous)) {
            error.miscellaneousError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (jobNumber && validateCustomField(jobNumber)) {
            error.JnumberError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (distributionChannel && validateCustomField(distributionChannel)) {
            error.distributionChannelError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (profitCenter && validateCustomField(profitCenter)) {
            error.profitCenterError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        if (companyArea && validateCustomField(companyArea)) {
            error.COAreaError = 'Enter a valid input';
            setErrorObj({ ...error });
            setIsError(true);
        }
        try {
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                sendEventsForEcommerceCheckoutOption(5, {
                    accountName: userAccount?.accountName
                });
                sendEventsForUpdateVirtualPath(
                    `${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_ADDITIONAL_INFO}`,
                    `/${
                        localStorage.getItem('companyID') == 2
                            ? VARIABLE_CONFIG.REGION.CANADA
                            : VARIABLE_CONFIG.REGION.US
                    }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.CUSTOM_ACCOUNT_ADDITIONAL_INFO}`
                );
            }
        } catch (error) {
            logError(error, false, 'handleSaveAndContinue');
        }
        setIsSubmit(true);
    };

    return (
        <>
            <h6 className="account-heading">{intl.formatMessage({ id: 'checkout:Disney-heading' })}</h6>
            <p className="account-message">{intl.formatMessage({ id: 'checkout:Disney-message' })}</p>
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-company-code' })}
                label={intl.formatMessage({ id: 'checkout:Disney-company-code' })}
                value={companyCode}
                handleInputChange={handleCompanyCode}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-company-code-placeholder' })}
                maxLength="4"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-company-code-placeholder' })}
                errorMsg={errorObj?.companyCodeError}
            />
            <Field label={intl.formatMessage({ id: 'checkout:Disney-gl-account' })} htmlFor="glAccount">
                <Dropdown
                    inputlabel={intl.formatMessage({ id: 'checkout:Disney-gl-account' })}
                    options={objGLAccount}
                    onChangeDropdownValue={handleGLAccountOption}
                    selectedState={glAccount}
                    inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-gl-account' })}
                />
            </Field>
            <Field label={intl.formatMessage({ id: 'checkout:Disney-business-area' })} htmlFor="businessArea">
                <Dropdown
                    inputlabel={intl.formatMessage({ id: 'checkout:Disney-business-area' })}
                    options={objBusinessArea}
                    onChangeDropdownValue={handleBusinessAreaOption}
                    selectedState={businessArea}
                    inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-business-area' })}
                />
            </Field>
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-co-area' })}
                label={intl.formatMessage({ id: 'checkout:Disney-co-area' })}
                value={companyArea}
                handleInputChange={handleCOArea}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-co-area-placeholder' })}
                maxLength="10"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-co-area-placeholder' })}
                errorMsg={errorObj?.COAreaError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-cost-center' })}
                label={intl.formatMessage({ id: 'checkout:Disney-cost-center' })}
                value={costCenter}
                handleInputChange={handleCostCenter}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-cost-center-placeholder' })}
                maxLength="10"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-cost-center-placeholder' })}
                errorMsg={errorObj?.costCenterError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-profit-center' })}
                label={intl.formatMessage({ id: 'checkout:Disney-profit-center' })}
                value={profitCenter}
                handleInputChange={handleProfitCenter}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-profit-center-placeholder' })}
                maxLength="10"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-profit-center-placeholder' })}
                errorMsg={errorObj?.profitCenterError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-WBS-element' })}
                label={intl.formatMessage({ id: 'checkout:Disney-WBS-element' })}
                value={wbsElement}
                handleInputChange={handleWBS}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-WBS-element-placeholder' })}
                maxLength="40"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-WBS-element-placeholder' })}
                errorMsg={errorObj?.wbsElementError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-J#-element' })}
                label={intl.formatMessage({ id: 'checkout:Disney-J#-element' })}
                value={jobNumber}
                handleInputChange={handleJNumber}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-J#-element-placeholder' })}
                maxLength="30"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-J#-element-placeholder' })}
                errorMsg={errorObj?.JnumberError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-PERNER' })}
                label={intl.formatMessage({ id: 'checkout:Disney-PERNER' })}
                value={perner}
                handleInputChange={handlePERNER}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-PERNER-placeholder' })}
                maxLength="10"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-PERNER-placeholder' })}
                errorMsg={errorObj?.PERNERError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-distribution-channel' })}
                label={intl.formatMessage({ id: 'checkout:Disney-distribution-channel' })}
                value={distributionChannel}
                handleInputChange={handleDistributionChannel}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-distribution-channel-placeholder' })}
                maxLength="10"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-distribution-channel-placeholder' })}
                errorMsg={errorObj?.distributionChannelError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-receiver-first' })}
                label={intl.formatMessage({ id: 'checkout:Disney-receiver-first' })}
                value={orderedFor}
                handleInputChange={handleReceiverFirst}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-receiver-first' })}
                maxLength="50"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-receiver-first' })}
                errorMsg={errorObj?.receiverFirstError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-approver-first' })}
                label={intl.formatMessage({ id: 'checkout:Disney-approver-first' })}
                value={approvedBy}
                handleInputChange={handleApproverFirst}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-approver-first' })}
                maxLength="50"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-approver-first' })}
                errorMsg={errorObj?.approverFirstError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-event-name' })}
                label={intl.formatMessage({ id: 'checkout:Disney-event-name' })}
                value={eventName}
                handleInputChange={handleEventName}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-event-name-placeholder' })}
                maxLength="100"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-event-name-placeholder' })}
                errorMsg={errorObj?.eventNameError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-miscellaneous' })}
                label={intl.formatMessage({ id: 'checkout:Disney-miscellaneous' })}
                value={miscellaneous}
                handleInputChange={handleMiscellaneous}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-miscellaneous' })}
                maxLength="40"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-miscellaneous' })}
                errorMsg={errorObj?.miscellaneousError}
            />
            <Input
                name={intl.formatMessage({ id: 'checkout:Disney-BVCC' })}
                label={intl.formatMessage({ id: 'checkout:Disney-BVCC' })}
                value={BVCC}
                handleInputChange={handleBVCC}
                placeholder={intl.formatMessage({ id: 'checkout:Disney-BVCC-placeholder' })}
                maxLength="30"
                inputAriaLabel={intl.formatMessage({ id: 'checkout:Disney-BVCC-placeholder' })}
                errorMsg={errorObj?.BVCCError}
            />
            <div className="saveAndContinueBtn">
                <Button
                    type="button"
                    data-testid={'btnID'}
                    onClick={handleSaveAndContinue}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    className="button button-primary button-block">
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        </>
    );
};
export default Disney;
