import React from 'react';
import { useIntl } from 'react-intl';
import { orderSummaryDataLocators } from '../../dataLocators';

const TBDDeliveryView = () => {
    const orderSummaryIntl = useIntl();
    const renderTBDDeliveryView = () => {
        return (
            <b data-testid={orderSummaryDataLocators.orderSummary_tbd_cta}>
                {orderSummaryIntl.formatMessage({ id: 'order-summary:checkout-tbd-acronym' })}
            </b>
        );
    };

    return renderTBDDeliveryView();
};

export default React.memo(TBDDeliveryView);
