import React from 'react';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import useOrderSummaryFactory from '../../hooks/useOrderSummaryFactory';
import InfoIcon from '../../../../../resources/images/info.svg';
import Button from '../../../../global/atoms/button/button';
import { summaryDetailsDatalocator } from '../../dataLocators';

const TooltipIcon = ({ feeTypeName }) => {
    const orderSummaryIntl = useIntl();
    const { handleIconClick } = useOrderSummaryFactory({ feeTypeName });

    return (
        <Button
            className="toolTipIcon"
            onClick={handleIconClick}
            data-testid={summaryDetailsDatalocator.tooltip_icon}
            tabIndex="0"
            customButtonAriaLabel={`${orderSummaryIntl.formatMessage({
                id: 'order-summary:commom-more-information'
            })} ${feeTypeName} ${orderSummaryIntl.formatMessage({ id: 'order-summary:common-graphic-clickable' })}`}>
            <InfoIcon aria-hidden="true" />
        </Button>
    );
};

export default React.memo(TooltipIcon);

TooltipIcon.propTypes = {
    feeTypeName: string
};

TooltipIcon.defaultProps = {
    feeTypeName: ''
};
