import React, { useEffect } from 'react';
import moment from 'moment';
import { func, number, string } from 'prop-types';
import { useCartState } from '../../../contexts/cart';
import EditHowToGetOrder from './editHowToGetYourOrder/EditHowToGetOrder';
import './howToGetOrder.scss';
import { useDidMount } from '../../../hooks/useDidMount';
import { logError } from '../../global/utils/logger';
import { useFilterState } from '../../cap';
import useCartEstimate from '../../App/hooks/useCartEstimate';
import ReadOnlyHowToGetOrder from './ReadOnlyHowToGetOrder';
import CollapsedHowToGetOrder from './CollapsedHowToGetOrder';

const HowToGetOrder = props => {
    const [{ cart, howToGetYourOrderDetails }] = useCartState();
    const [{ viewCart, startDate, endDate }, filterDispatch] = useFilterState();
    const { isInStorePickup } = viewCart;
    const { currentStep, handleStepChange, currentOffSet, formStep, isCustomAccount } = props;
    const stepNumber = formStep.HTGO;
    const didMount = useDidMount();
    const [{ getEstimates }] = useCartEstimate();

    useEffect(() => {
        function deliveryTimeEstimatesUpdate() {
            try {
                if (didMount && currentStep >= stepNumber) {
                    getEstimates('', 0, '', '', true, cart?.availableCartItems);
                }
            } catch (err) {
                logError(err, false, 'deliveryTimeEstimatesUpdate');
            }
        }
        deliveryTimeEstimatesUpdate();
    }, [
        howToGetYourOrderDetails?.selectedPickupTime,
        howToGetYourOrderDetails?.selectedReturnTime,
        howToGetYourOrderDetails?.selectedPickUpTime?.label,
        howToGetYourOrderDetails?.selectedDeliveryTime?.label,
        didMount
    ]);

    return (
        <div className={'howToGetOrder'}>
            {currentStep < stepNumber && <CollapsedHowToGetOrder formStep={formStep} />}
            {currentStep === stepNumber && (
                <EditHowToGetOrder
                    startDate={startDate}
                    endDate={endDate}
                    currentStep={currentStep}
                    currentOffSet={currentOffSet}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyHowToGetOrder
                    handleStepChange={handleStepChange}
                    startDate={moment(startDate).format('MMM DD, YYYY')}
                    endDate={moment(endDate).format('MMM DD, YYYY')}
                    selectedStartTime={howToGetYourOrderDetails.selectedPickupTime}
                    selectedEndTime={howToGetYourOrderDetails.selectedReturnTime}
                    isInstore={isInStorePickup}
                    storeDetails={howToGetYourOrderDetails.selectedStoreDetails}
                    deliveryTime={howToGetYourOrderDetails.selectedDeliveryTime.label}
                    pickupTime={howToGetYourOrderDetails.selectedPickUpTime.label}
                    formStep={formStep}
                />
            )}
        </div>
    );
};

export default HowToGetOrder;

HowToGetOrder.propsType = {
    currentStep: number,
    handleStepChange: func,
    currentOffSet: string
};

HowToGetOrder.defaultProps = {
    currentStep: 0,
    handleStepChange: () => {},
    currentOffSet: ''
};
