import { func, string } from 'prop-types';
import React from 'react';
import DropdownIcon from '../../../../resources/images/_dropdown-icon.svg';
import './dropdown.scss';

const Dropdown = props => {
    const { options, onChangeDropdownValue, selectedState, placeholderText, dropDownTestId, isError, className, inputlabel, onFocus, errorClass, elementId } = props;

    const onChangeHandler = e => {
        onChangeDropdownValue(e.target.value);
    };

    const onDropdownFocus = () => {
        onFocus();
    };

    return (
        <div className={`${isError ? 'dropdownRootError' : 'dropdownRoot'} ${errorClass}`} id={elementId}>
            <select
                id={inputlabel}
                className={`${className} cmp-Field__field__input ${selectedState==="" && 'unselected-option'} ${errorClass}`}
                value={selectedState}
                onChange={onChangeHandler}
                data-testid={dropDownTestId}
                onFocus={onDropdownFocus}>
                {placeholderText && (
                    <option value="" disabled >
                        {placeholderText}
                    </option>
                )}
                {options?.map(data => (
                    <option key={data.value} value={data.value}>
                        {data.label}
                    </option>
                ))}
            </select>
            <DropdownIcon className="dropdownIcon" />
        </div>
    );
};
export default Dropdown;

Dropdown.propTypes = {
    className: string,
    onFocus: func,
    errorClass: string,
    elementId: string
}

Dropdown.defaultProps ={
    className: '',
    onFocus: () => {},
    errorClass: '',
    elementId: ''
}