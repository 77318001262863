import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useCartState } from '../../../../contexts/cart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import Error from '../../../../resources/images/error.svg';
import { useFilterState } from '../../../cap';
import Alert from '../../../global/atoms/alert/alert';
import Dropdown from '../../../global/atoms/dropdown';
import { getDifferenceinHours } from '../../utils/checkoutUtils';
import { logError, logWarning } from '../../../global/utils/logger';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import { CHECKOUT_PICKUPTIME_ID, CHECKOUT_RETURNTIME_ID } from '../../constants';
import './../howToGetOrder.scss';

export const PickUpandReturnTime = ({
    isPickUpError,
    pickUpTimeSlots,
    returnTimeSlots,
    selectedReturnTime,
    selectedPickupTime,
    isReturnError,
    setIsReturnError,
    setSelectedPickupTime,
    setSelectedReturnTime,
    currentOffSet,
    selectedEndDate,
    selectedStartDate,
    setIsPickUpError,
    checkoutErrorHandlingAnalytics
}) => {
    const [, dispatch] = useCartState();
    const [{ startDate, selectedStoreDetails }] = useFilterState();
    const intl = useIntl();
    const { sendEventsForClick } = useAnalyticsContext();

    const isStoreUnavailableToday = () => {
        if (moment(startDate).format('MMM DD, YYYY') === moment(currentOffSet).format('MMM DD, YYYY')) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (returnTimeSlots?.length === 0) {
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'htgo:store-return-date-closed-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
            );
        }
    }, []);

    const choseStoreAnalytics = () => {
        if (
            (new Date(startDate)?.getDay() == 0 && !selectedStoreDetails?.operatingHours?.[0]?.isOpen) ||
            (new Date(startDate)?.getDay() == 6 && !selectedStoreDetails?.operatingHours?.[6]?.isOpen)
        ) {
            try {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                    `${VARIABLE_CONFIG.EVENT_ACTION.IN_STORE_PICKUP_ERROR}::${intl.formatMessage({
                        id: 'in-store_weekend_date'
                    })}`,
                    `${moment(startDate).format('l')}::${selectedStoreDetails?.pc}` // store number // pickup date
                );
            } catch (error) {
                logError(error, false, 'choseStoreAnalytics');
            }
        } else if (isStoreUnavailableToday()) {
            try {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                    `${VARIABLE_CONFIG.EVENT_ACTION.IN_STORE_PICKUP_ERROR}::${intl.formatMessage({
                        id: 'start_date_updated_based_on_store_availability'
                    })}`,
                    `${moment(startDate).format('l')}::${selectedStoreDetails?.pc}` // store number // pickup date
                );
            } catch (error) {
                logError(error, false, 'choseStoreAnalytics');
            }
        }
    };
    useEffect(() => {
        choseStoreAnalytics();
    }, [isStoreUnavailableToday()]);

    const makePickupSlotArray = () => {
        const arrayToSend = [];
        pickUpTimeSlots.forEach(item => {
            const obj = {};
            obj.label = item;
            obj.value = item;
            arrayToSend.push(obj);
        });

        return arrayToSend;
    };

    const makeReturnSlotArray = () => {
        const arrayToSend = [];
        returnTimeSlots.forEach(item => {
            const obj = {};
            obj.label = item;
            obj.value = item;
            arrayToSend.push(obj);
        });
        return arrayToSend;
    };

    const handlePickupTime = selectedValue => {
        setIsPickUpError(false);
        setSelectedPickupTime(selectedValue);
        setIsReturnError(false);
        if (selectedReturnTime && selectedPickupTime) {
            const start = moment(selectedStartDate);
            const startDateFormat = start.format('yyyy-MM-DD');
            const enddate = moment(selectedEndDate);
            const endDateFormat = enddate.format('yyyy-MM-DD');
            const sd = moment(`${startDateFormat} ${selectedValue}`, 'yyyy-MM-DD hh:mm A');
            const ed = moment(`${endDateFormat} ${selectedReturnTime}`, 'yyyy-MM-DD hh:mm A');
            const difference = getDifferenceinHours(sd, ed);
            if (difference < 24) {
                checkoutErrorHandlingAnalytics(
                    intl.formatMessage({ id: 'checkout:return-time-less-than-24hrs' }),
                    VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
                );
                setIsReturnError(true);
            }
        }
    };

    const handleReturnTime = selectedValue => {
        setIsReturnError(false);
        setSelectedReturnTime(selectedValue);
        // todo-------
        const start = moment(selectedStartDate);
        const startDateFormat = start.format('yyyy-MM-DD');
        const enddate = moment(selectedEndDate);
        const endDateFormat = enddate.format('yyyy-MM-DD');
        const sd = moment(`${startDateFormat} ${selectedPickupTime}`, 'yyyy-MM-DD hh:mm A');
        const ed = moment(`${endDateFormat} ${selectedValue}`, 'yyyy-MM-DD hh:mm A');
        const difference = getDifferenceinHours(sd, ed);
        if (difference < 24) {
            setIsReturnError(true);
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:return-time-less-than-24hrs' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
            );
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
        }
    };

    const renderTimeSlotsWithoutErrors = isOriginalStartDate => {
        return (
            <div className={'HTGO_timeContainerRoot'}>
                <div className={'HTGO_timeContainer'}>
                    {/* <div className={'HTGO_dateContainerItem'}> */}
                    {/* <span data-testid={checkoutDatalocator.checkout_rentalsstart_label_testid}>
                            {intl.formatMessage({ id: 'checkout:rental-start' })}
                        </span>
                        <span>{`: `}</span>
                        <span data-testid={checkoutDatalocator.checkout_pickUptime_field_testid}>
                            {moment(isOriginalStartDate ? startDate : selectedStartDate).format('MMM DD, YYYY')}
                        </span> */}
                    {/* </div> */}
                    <label htmlFor={CHECKOUT_PICKUPTIME_ID}>
                        <small className={'HTGO_dateContainerLabel'}>{intl.formatMessage({ id: 'pickup_time' })}</small>
                    </label>
                    <Dropdown
                        className="HTGO_dropdownClass"
                        isError={isPickUpError}
                        options={makePickupSlotArray() || []}
                        onChangeDropdownValue={handlePickupTime}
                        selectedState={selectedPickupTime}
                        inputlabel={CHECKOUT_PICKUPTIME_ID}
                        dropDownTestId={checkoutDatalocator.checkout_PickUpTimedropdown_label_testid}
                        placeholderText={intl.formatMessage({ id: 'checkout:available-times' })}
                    />
                    {isPickUpError && (
                        <div className="HTGO_inlineError">
                            <Error />
                            <small>{intl.formatMessage({ id: 'common:required-input-error' })}</small>
                        </div>
                    )}
                </div>
                <div className={'HTGO_timeContainer'}>
                    {/* <div className={'HTGO_dateContainerItem'}> */}
                    {/* <span data-testid={checkoutDatalocator.checkout_rentalsend_label_testid}>
                            {intl.formatMessage({ id: 'checkout:rental-end' })}
                        </span>
                        <span>{`: `}</span>
                        <span data-testid={checkoutDatalocator.checkout_returnTime_field_testid}>
                            {moment(isOriginalStartDate ? endDate : selectedEndDate).format('MMM DD, YYYY')}
                        </span> */}
                    {/* </div> */}
                    <label htmlFor={CHECKOUT_RETURNTIME_ID}>
                        <small className={'HTGO_dateContainerLabel'}>{intl.formatMessage({ id: 'return_time' })}</small>
                    </label>
                    <Dropdown
                        className="HTGO_dropdownClass"
                        isError={isReturnError}
                        options={makeReturnSlotArray()}
                        onChangeDropdownValue={handleReturnTime}
                        selectedState={selectedReturnTime}
                        inputlabel={CHECKOUT_RETURNTIME_ID}
                        dropDownTestId={checkoutDatalocator.checkout_ReturnTimedropdown_label_testid}
                        placeholderText={intl.formatMessage({ id: 'checkout:available-times' })}
                    />
                    {isReturnError && (
                        <div className="HTGO_inlineError">
                            <Error />
                            <small>
                                {selectedReturnTime
                                    ? intl.formatMessage({ id: 'checkout:return-time-less-than-24hrs' })
                                    : intl.formatMessage({ id: 'common:required-input-error' })}
                            </small>
                        </div>
                    )}
                    {returnTimeSlots.length === 0 && (
                        <div className="HTGO_inlineError">
                            <Error />
                            <small>{intl.formatMessage({ id: 'htgo:store-return-date-closed-error' })}</small>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const storesNotAvailableToday = () => {
        return (
            <div className={'pickUpReturn_warningMessage'}>
                {isStoreUnavailableToday() ? (
                    <>
                        <Alert
                            className="alert alert-withmargin"
                            type={'warning'}
                            message={intl.formatMessage({ id: 'store_cant_fullfill_today' })}
                        />
                    </>
                ) : (
                    <>
                        <Alert
                            className="alert alert-withmargin"
                            type={'warning'}
                            message={intl.formatMessage({ id: 'store_cant_fullfill' })}
                        />
                    </>
                )}
            </div>
        );
    };

    const renderErrorState = () => {
        return storesNotAvailableToday();
    };

    const renderTimeSlots = () => {
        if (pickUpTimeSlots !== null) {
            if (pickUpTimeSlots?.length > 0) {
                return renderTimeSlotsWithoutErrors(true);
            } else {
                return renderErrorState();
            }
        }
    };

    return <>{renderTimeSlots()}</>;
};
