import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import {
    SET_SHOW_RENTAL_EQUIPMENT_SCREEN,
    SET_SUBMIT_RESERVATION,
    SET_VIEW_CART_FIELDS
} from '../../../aem-core-components/actions/constants';
import { VIEW_CART } from '../../../constants/cartConstants';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { triggerEvents } from '../../../constants/analyticsConstants/triggerEvents';
import useCheckout from '../../../hooks/useCheckout';
import { useCheckUser } from '../../../hooks/useCheckUser';
import config from '../../App/config';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons';
import AlertModal from '../../global/modules/modals/alertModal/AlertModal';
import { logError } from '../../global/utils/logger';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { BACK_TO_CART_CTA_ID, SUNBELT_HEADER_LOGO_CTA_ID } from '../constants';
import { useFilterState } from '../../cap';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';

export default function CheckoutPageAlerts({ formStep, handleStepChange }) {
    const intl = useIntl();
    const [{ submitReservation }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const { isInStorePickup } = viewCart;
    const [isBackToCartAlertModalOpen, setIsBackToCartAlertModalOpen] = useState(false);
    const [isBackToHomeAlertModalOpen, setIsBackToHomeAlertModalOpen] = useState(false);
    const { sendEventsForClick } = useAnalyticsContext();
    const { clearCheckout } = useCheckout();
    const userType = useCheckUser();

    useEffect(() => {
        if (document.getElementById(BACK_TO_CART_CTA_ID)) {
            document.getElementById(BACK_TO_CART_CTA_ID).removeAttribute('href');
            document.getElementById(BACK_TO_CART_CTA_ID).blur();
            document
                .getElementById(BACK_TO_CART_CTA_ID)
                .setAttribute('data-testid', checkoutDatalocator.guest_backtocart_link_testid);
            document.getElementById(BACK_TO_CART_CTA_ID).onclick = () => {
                //the following conditions checks if the user is a credit/cash user
                //if yes, then it directly goes back to previous screen, otherwise
                //the alert box will be displayed
                setIsBackToCartAlertModalOpen(true);
            };
        }
    }, []);

    useEffect(() => {
        if (document.getElementById(SUNBELT_HEADER_LOGO_CTA_ID)) {
            const headerLogoLink = document.querySelector(`#${SUNBELT_HEADER_LOGO_CTA_ID} a`);
            if (headerLogoLink) {
                headerLogoLink?.removeAttribute('href');
                headerLogoLink.title = 'Sunbelt Rentals Header Logo';
                headerLogoLink.tabIndex = 0;
            }
            document
                .getElementById(SUNBELT_HEADER_LOGO_CTA_ID)
                .setAttribute('data-testid', checkoutDatalocator.guest_sbrlogo_testid);
            document.getElementById(SUNBELT_HEADER_LOGO_CTA_ID).onclick = () => {
                setIsBackToHomeAlertModalOpen(true);
            };
        }
    }, []);

    const handleIsBackToCartCloseModal = () => {
        try {
            triggerEvents(`${userType} select`, intl.formatMessage({ id: 'common:cancel' }), sendEventsForClick);
        } catch (error) {
            logError(error, false, 'handleIsBackToCartCloseModal');
        }
        setIsBackToCartAlertModalOpen(false);
    };
    const handleIsBackToHomeCloseModal = () => {
        try {
            triggerEvents(`${userType} select`, intl.formatMessage({ id: 'common:cancel' }), sendEventsForClick);
        } catch (error) {
            logError(error, false, 'handleIsBackToHomeCloseModal');
        }
        setIsBackToHomeAlertModalOpen(false);
    };

    const handleIsBackToCartAlertReturnClick = () => {
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'checkout:return-to-cart-text' }),
                sendEventsForClick
            );
        } catch (error) {
            logError(error, false, 'handleIsBackToCartAlertReturnClick');
        }

        let localStartDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
        let localEndDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, localStartDate);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, localEndDate);
        localStorage.setItem('checkoutReferrer', true);
        setIsBackToCartAlertModalOpen(false);
        //sessionStorage.setItem('isOpen', true);
        localStorage.setItem('projectDetails', JSON.stringify(projectDetails));
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ESTIMATE_ON_CART_OPEN_REQUIRED, true);
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CAP_IN_EDIT_MODE, true);
        window.location.href = window.location.origin + config.pagePaths.cartPage;
        // if (document.referrer === '' || !document.referrer.includes('/equipment-rental/')) {
        //     window.location.href = window.location.origin + config.pagePaths.homePage;
        // } else if (document.referrer) {
        //     window.location = document.referrer;
        // } else {
        //     history.back();
        // }
    };
    const handleIsBackToHomeAlertReturnClick = () => {
        let localStartDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || '';
        let localEndDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) || '';
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, localStartDate);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, localEndDate);
        localStorage.setItem('checkoutReferrer', true);
        localStorage.setItem('projectDetails', JSON.stringify(projectDetails));
        setIsBackToHomeAlertModalOpen(false);
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'checkout:return-to-cart-text' }),
                sendEventsForClick
            );
        } catch (error) {
            logError(error, false, 'handleIsBackToHomeAlertReturnClick');
        }

        window.location.href = window.location.origin + config.pagePaths.homePage;
    };

    const handleContinueClick = () => {
        window.scrollTo(0, 0);
        document.querySelector('.block-checkout-left').scrollTo(0, 0);
        clearCheckout(formStep, formStep?.ORDER_DETAILS);
        dispatch({ type: SET_SHOW_RENTAL_EQUIPMENT_SCREEN, flag: false });
        clearInterval(viewCart?.checkPCInterval);
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.SHOW_INACTIVE_PC_ALERT, value: false });
        handleStepChange(formStep?.ORDER_DETAILS);
        window.scrollTo(0, 0);
    };
    const renderPasswordContent = () => {
        return (
            <div className="passwordModal" tabIndex={0}>
                {intl.formatMessage({ id: 'submit_reservation_password_modal_line1' })}
                <ul className="pswrdRequirements">
                    <li className="random">{intl.formatMessage({ id: 'submit_reservation_password_modal_req1' })}</li>
                    <li>{intl.formatMessage({ id: 'submit_reservation_password_modal_req2' })}</li>
                    <li>{intl.formatMessage({ id: 'submit_reservation_password_modal_req3' })}</li>
                    <li>{intl.formatMessage({ id: 'submit_reservation_password_modal_req4' })}</li>
                </ul>
            </div>
        );
    };
    const handlePasswordModalClose = () => {
        dispatch({
            type: SET_SUBMIT_RESERVATION,
            key: 'isPasswordModalOpen',
            value: false
        });
    };

    return (
        <>
            {/* Warning modal for 'Back to cart' click */}
            <AlertModalWithButtons
                isOpen={isBackToCartAlertModalOpen}
                title={intl.formatMessage({ id: 'checkout:return-to-cart-question' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:return-to-cart-modal-question' })}
                handleContinueClick={handleIsBackToCartAlertReturnClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:return-to-cart-text' })}
                handleCancelClick={handleIsBackToCartCloseModal}
                cancelButtonText={intl.formatMessage({ id: 'common:cancel' })}
                closeIconDataLocator={checkoutDatalocator.guest_back_to_cart_modal_close_testid}
                continueButtonDataLocator={checkoutDatalocator.guest_return_to_cart_btn_testid}
                cancelButtonDataLocator={checkoutDatalocator.guest_back_to_cart_modal_cancel_testid}
            />
            {/* Warning modal for 'SBR logo' click */}
            <AlertModalWithButtons
                isOpen={isBackToHomeAlertModalOpen}
                title={intl.formatMessage({ id: 'checkout:return-to-homepage-question' })}
                warningQuestion={intl.formatMessage({
                    id: 'checkout:return-to-homepage-modal-question'
                })}
                handleContinueClick={handleIsBackToHomeAlertReturnClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:return-to-homepage-text' })}
                handleCancelClick={handleIsBackToHomeCloseModal}
                cancelButtonText={intl.formatMessage({ id: 'common:cancel' })}
                closeIconDataLocator={checkoutDatalocator.guest_back_to_homepage_modal_close_testid}
                continueButtonDataLocator={checkoutDatalocator.guest_return_to_homepage_btn_testid}
                cancelButtonDataLocator={checkoutDatalocator.guest_back_to_homepage_modal_cancel_testid}
            />
            {/* Warning modal for session inactivity and store not available  */}
            <AlertModalWithButtons
                isOpen={viewCart?.inactivePCAlert}
                title={
                    isInStorePickup
                        ? intl.formatMessage({ id: 'checkout:store-not-available-alert-title' })
                        : intl.formatMessage({ id: 'checkout:session-inactivity-alert-title' })
                }
                warningQuestion={
                    isInStorePickup
                        ? intl.formatMessage({ id: 'checkout:store-not-available-alert-description' })
                        : intl.formatMessage({ id: 'checkout:session-inactivity-alert-description' })
                }
                handleContinueClick={handleContinueClick}
                handleCancelClick={handleContinueClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:page-timeout-alert' })}
                continueButtonDataLocator={checkoutDatalocator.guest_return_to_homepage_btn_testid}
            />
            {submitReservation.isPasswordModalOpen && (
                <AlertModal
                    title={intl.formatMessage({ id: 'submit_reservation_password_modal_title' })}
                    content={renderPasswordContent()}
                    handleCloseModal={handlePasswordModalClose}
                    isModalOpen={submitReservation.isPasswordModalOpen}
                    closeIconDataLocator={checkoutDatalocator.submit_reservation}
                />
            )}
        </>
    );
}
