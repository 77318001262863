import React from 'react';
import { func, number, string } from 'prop-types';
import { FACTORYSOURCE } from '../../constants/orderSummaryFactoryConstants';
import { orderSummaryDataLocators } from '../../dataLocators';

const GetEstimatesView = ({ source, isDelivery, deliveryPickUpCharges }) => {
    const renderGetEstimatesView = () => {
        switch (source) {
            case FACTORYSOURCE.MINICART:
                return isDelivery() ? (
                    <b data-testid={orderSummaryDataLocators.miniCartOverlay_getEstimate_cta}>
                        {deliveryPickUpCharges}
                    </b>
                ) : (
                    <b>{'-'}</b>
                );
            case FACTORYSOURCE.CART:
                return isDelivery() ? (
                    <b data-testid={orderSummaryDataLocators.miniCartOverlay_getEstimate_cta}>
                        {deliveryPickUpCharges}
                    </b>
                ) : (
                    <b>{'-'}</b>
                );
            case FACTORYSOURCE.CHECKOUT:
                return isDelivery() ? <b>{deliveryPickUpCharges}</b> : <b>{'-'}</b>;
            default:
                return null;
        }
    };

    return renderGetEstimatesView();
};

export default React.memo(GetEstimatesView);

GetEstimatesView.propTypes = {
    source: string,
    isDelivery: func,
    deliveryPickUpCharges: string
};
GetEstimatesView.defaultProps = {
    source: '',
    isDelivery: () => {},
    deliveryPickUpCharges: ''
};
