import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import ViewAll from '../../../global/modules/viewAll';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import '../accessoriesAndAddons.scss';
import { ENV_CONFIG } from '../../../../constants/envConfig';

const Addons = props => {
    const { consumables, renderItem, setIsAddonViewAll } = props;
    const intl = useIntl();
    const maxAddOnsLimit = parseInt(ENV_CONFIG.MAXADDONS);

    return (
        <>
            <h6
                className="modal-rental-equipment-header"
                data-testid={checkoutDatalocator.checkout_rental_equipment_addons_heading}>
                {intl.formatMessage({ id: 'checkout:rental-equipments-addons-header' })}
            </h6>
            <ViewAll
                items={consumables?.selectedRentalEquipment?.equipmentItems?.consumables}
                renderItem={renderItem}
                limit={maxAddOnsLimit}
                viewAllClass="view-all-btn"
                count={
                    consumables?.availableQuantity?.totalAddOns[
                        consumables?.selectedRentalEquipment?.equipmentInfo?.sku
                    ]
                }
                handleViewAll={setIsAddonViewAll}
            />
        </>
    );
};

export default memo(Addons);
