import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { DELIVERY_CHARGE_FLAG } from '../../../../../constants/cartConstants';
import { FACTORYSOURCE } from '../../constants/orderSummaryFactoryConstants';
import {
    checkoutOrderSummaryDataLocators,
    orderSummaryDataLocators,
    quoteSummaryDataLocators
} from '../../dataLocators';
import FreeDeliveryView from '../freeDeliveryView/FreeDeliveryView';
import GetEstimateView from '../getEstimateView/GetEstimateView';
import OrderSummaryLineItem from '../orderSummaryLineItem/OrderSummaryLineItem';
import TBDDeliveryView from '../tbdDeliveryView/TBDDeliveryView';

const RoundTripFee = ({
    source,
    deliveryPickUpCharges,
    deliveryChargeFlag,
    showDeliveryEstimate,
    handleRoundTripAndEstimateFlow,
    isDelivery,
    dispatch
}) => {
    const orderSummaryIntl = useIntl();
    const renderDeliveryFlagView = () => {
        switch (deliveryChargeFlag) {
            case DELIVERY_CHARGE_FLAG.FREE:
                return <FreeDeliveryView source={source}></FreeDeliveryView>;
            case DELIVERY_CHARGE_FLAG.TBD:
                return (
                    <TBDDeliveryView
                        source={source}
                        showDeliveryEstimate={showDeliveryEstimate}
                        handleRoundTripAndEstimateFlow={handleRoundTripAndEstimateFlow}></TBDDeliveryView>
                );
            default:
                return (
                    <GetEstimateView
                        source={source}
                        isDelivery={isDelivery}
                        showDeliveryEstimate={showDeliveryEstimate}
                        deliveryPickUpCharges={deliveryPickUpCharges}
                        handleRoundTripAndEstimateFlow={handleRoundTripAndEstimateFlow}></GetEstimateView>
                );
        }
    };

    const renderRoundTripFee = () => {
        switch (source) {
            case FACTORYSOURCE.CART:
                return (
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOVerlay_roundTripDelivery_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                        itemValue={renderDeliveryFlagView()}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOVerlay_roundTripDelivery_dynValue}
                        dispatch={dispatch}
                    />
                );
            case FACTORYSOURCE.CHECKOUT:
                return (
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOVerlay_roundTripDelivery_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                        itemValue={renderDeliveryFlagView()} // need to validate while testing - if checkout then <b></b>
                        itemValueDataTestId={
                            checkoutOrderSummaryDataLocators.miniCartOVerlay_roundTripDelivery_dynValue
                        }
                        dispatch={dispatch}
                    />
                );
            case FACTORYSOURCE.QUOTE:
                const renderDeliveryView = () => {
                    return <b>{deliveryPickUpCharges}</b>;
                };
                return (
                    <OrderSummaryLineItem
                        itemLabel={orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                        itemLabelDataTestId={quoteSummaryDataLocators.quoteSummary_roundTripDelivery_lbl}
                        isTooltipIcon
                        tooltipIconMessage={orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                        itemValue={renderDeliveryView()} // need to validate while testing - if checkout then <b></b>
                        itemValueDataTestId={quoteSummaryDataLocators.quoteSummary_roundTripDelivery_dynValue}
                        dispatch={dispatch}
                    />
                );
            default:
                return null;
        }
    };

    return renderRoundTripFee();
};

export default React.memo(RoundTripFee);

RoundTripFee.propTypes = {
    source: string,
    deliveryPickUpCharges: number,
    deliveryChargeFlag: string,
    showDeliveryEstimate: bool,
    handleRoundTripAndEstimateFlow: func,
    isDelivery: func,
    dispatch: func
};
RoundTripFee.defaultProps = {
    source: null,
    deliveryPickUpCharges: 0,
    deliveryChargeFlag: '',
    showDeliveryEstimate: false,
    handleRoundTripAndEstimateFlow: () => {},
    isDelivery: () => {},
    dispatch: () => {}
};
