import React from 'react';
import { bool, string, func, number, oneOf } from 'prop-types';
import { useIntl } from 'react-intl';
import { Price } from '../../../aem-core-components';
import Checkbox from '../../global/atoms/Checkbox/Checkbox';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useSunbeltLocation } from '../../../hooks/useSunbeltLocation';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import classes from '../checkoutAndOrderSummary/checkoutAndOrderSummary.css';
import config from '../../App/config';
import { logError } from '../../global/utils/logger';
export default function OptionalPlanCheckbox({
    isChecked,
    type,
    handleOnClick,
    viewMoreLink,
    amount,
    title,
    description,
    dataTestId,
    id
}) {
    const intl = useIntl();
    const { sendEventsForClick } = useAnalyticsContext();
    const sunbeltLocation = useSunbeltLocation();
    const userType = useCheckUser();
    const handleClick = () => {
        handleOnClick(type);
    };
    const handleLearnMoreClick = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_OPTIONAL_PLANS,
                type == 'rpp'
                    ? VARIABLE_CONFIG.EVENT_LABEL.LEARN_MORE_RENTAL_PROTECTION
                    : VARIABLE_CONFIG.EVENT_LABEL.LEARN_MORE_PREPAY_FUEL
            );
        } catch (error) {
            logError(error, false, 'handleLearnMoreClick');
        }
    };

    const renderLabel = () => {
        return (
            <div className={classes.optionalPlanDescContainer}>
                <span className={classes.optionalPlanTitle} id={id}>
                    {title} (<Price className={classes.customPriceFont} currencyCode="USD" value={amount} />)
                </span>
                <small className={classes.optionalPlanDesc}>{description}</small>
                <span className={classes.optionalViewMore}>
                    <a
                        href={viewMoreLink}
                        onClick={handleLearnMoreClick}
                        target={'_blank'}
                        aria-label={`${intl.formatMessage({
                            id: 'common:learn-more'
                        })} ${title} ${intl.formatMessage({
                            id: 'open-new-window'
                        })}`}>
                        {intl.formatMessage({ id: 'common:learn-more' })}
                    </a>
                </span>
            </div>
        );
    };
    return (
        <div className={classes.optionalPlanWrapper}>
            <Checkbox
                label={renderLabel()}
                customAriaLabel={title}
                isChecked={isChecked}
                onClick={handleClick}
                dataTestId={dataTestId}
            />
        </div>
    );
}

OptionalPlanCheckbox.defaultProps = {
    isChecked: bool,
    type: oneOf(['rpp', 'fuel']),
    handleOnClick: func,
    viewMoreLink: string,
    amount: number,
    title: string,
    description: string,
    id: string
};

OptionalPlanCheckbox.defaultProps = {
    isChecked: false,
    type: 'rpp',
    handleOnClick: () => {},
    viewMoreLink: config?.pagePaths?.homePage,
    amount: 0,
    title: '',
    description: '',
    id: ''
};
