import React from 'react';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import { FACTORYSOURCE } from '../../constants/orderSummaryFactoryConstants';
import { checkoutOrderSummaryDataLocators, orderSummaryDataLocators } from '../../dataLocators';

const FreeDeliveryView = ({ source }) => {
    const orderSummaryIntl = useIntl();
    const renderFreeDeliveryView = () => {
        switch (source) {
            case FACTORYSOURCE.CART:
                return (
                    <b data-testid={orderSummaryDataLocators.miniCartOverlay_roundtrip_free_text}>
                        {orderSummaryIntl.formatMessage({ id: 'order-summary:free-delivery-pickup-text' })}
                    </b>
                );
            case FACTORYSOURCE.CHECKOUT:
                return (
                    <b data-testid={checkoutOrderSummaryDataLocators.miniCartOverlay_roundtrip_free_text}>
                        {orderSummaryIntl.formatMessage({ id: 'order-summary:free-delivery-pickup-text' })}
                    </b>
                );
            default:
                return null;
        }
    };

    return renderFreeDeliveryView();
};

export default React.memo(FreeDeliveryView);

FreeDeliveryView.propTypes = {
    source: string
};
FreeDeliveryView.defaultProps = {
    source: ''
};
