import { isCardExpired } from './paymentHelper';

export const filterPayments = paymentData => {
    let latestToBeSelected = -1;
    let filteredList = [];
    if (paymentData?.customerPaymentToken) {
        filteredList = paymentData?.customerPaymentToken?.sort(function (obj1, obj2) {
            return new Date(obj2.tokenLastUsedTimestamp) - new Date(obj1.tokenLastUsedTimestamp);
        });
        for (let index = 0; index < filteredList.length; index++) {
            if (!isCardExpired(filteredList[index].expiryDate)) {
                latestToBeSelected = index;
                break;
            }
        }
    }
    return { filteredList, latestToBeSelected };
};
