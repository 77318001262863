import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCookieValue } from '../../../aem-core-components/utils/hooks';
import config from '../../App/config';
import { postCartDetailsToPunchout } from '../../global/api/CommonResponseHandler';
import ButtonWithTnCCheckbox from '../../global/atoms/ButtonWithTnCCheckbox/ButtonWithTnCCheckbox';
import { generateSKU } from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';
import useReservation from '../../checkoutv2/hooks/useReservation';
import './punchoutOrderConfirmation.scss';
import '../../checkoutv2/checkout.scss';
import { SET_IS_RESERVATION_CLICKED } from '../../../aem-core-components/actions/constants';

export default function PunchoutOrderConfirmation({ customClass }) {
    const intl = useIntl();
    const [{ isLoading }, dispatch] = useCartState();
    const [, { dispatch: userDispatch }] = useUserContext();
    const [{ createPunchoutOrder, postPunchoutOrderPayload }] = useReservation();
    const [isChecked, setIsChecked] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [productItems, setProductItems] = useState([]);
    const [punchoutReturnURL] = useCookieValue('punchout_return_url');
    const [punchoutRedirectURL] = useCookieValue('punchout_redirect_url');

    useEffect(() => {
        if (isChecked) {
            setErrorText('');
        }
    }, [isChecked]);

    useEffect(() => {
        const callPostPunchout = async () => {
            const productLineItems = {};
            const otherLineItems = [];
            if (productItems?.length > 0) {
                productItems?.forEach(item => {
                    if (item?.catId !== 0 && item?.classId !== 0) {
                        const productId = generateSKU(item?.catId, item?.classId);
                        productLineItems[productId] = { ...item };
                    } else {
                        otherLineItems.push(item);
                    }
                });
            }
            const payload = await postPunchoutOrderPayload(orderId, productLineItems, otherLineItems);
            await postCartDetailsToPunchout(punchoutReturnURL, payload);
            onOrderSuccess();
        };
        if (orderId && productItems?.length > 0) {
            callPostPunchout();
            setOrderId(null);
        }
    }, [orderId, productItems]);

    const onClickTransferOrder = async () => {
        if (!isChecked) {
            setErrorText(intl.formatMessage({ id: 'punchout:t&c-error' }));
            return;
        }
        if (!punchoutRedirectURL || !punchoutReturnURL) {
            logError('punchout urls not found', true, 'onClickTransferOrder');
        }
        dispatch({ type: SET_IS_RESERVATION_CLICKED, key: 'isReservationClicked', value: true });
        dispatch({ type: 'beginLoading' });
        const { data } = await createPunchoutOrder();
        if (!orderId && data?.orderId) {
            setOrderId(data?.orderId);
            setProductItems(data?.productItems && data?.productItems?.length > 0 ? data?.productItems : []);
        } else {
            logError(JSON.stringify(data), true, 'onClickTransferOrder');
        }
        dispatch({ type: 'endLoading' });
        dispatch({ type: SET_IS_RESERVATION_CLICKED, key: 'isReservationClicked', value: false });
    };

    const onOrderSuccess = () => {
        const redirectURL = punchoutRedirectURL;
        dispatch({ type: 'reset' });
        userDispatch({ type: 'resetUser' });
        window.parent.location.href = redirectURL;
    };

    const onCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    const checkboxLabel = () => {
        return (
            <span className="tnC">
                {intl.formatMessage({ id: 'terms&conditions:prefix' })}{' '}
                <a
                    href={
                        parseInt(localStorage.getItem('companyID')) === 2
                            ? config?.pagePaths?.tncCA
                            : config?.pagePaths?.tncUS
                    }
                    target="_blank"
                    className="highlighted_tnC">
                    {intl.formatMessage({ id: 'terms&conditions:label' })}
                </a>
                .
            </span>
        );
    };

    return (
        <>
            <ButtonWithTnCCheckbox
                customClass={customClass}
                isCheckboxVisible={true}
                isChecked={isChecked}
                onCheckboxClick={onCheckboxClick}
                checkboxLabel={checkboxLabel}
                errorText={errorText}
                buttonTitle={intl.formatMessage({ id: 'punchout:transfer-order-cta' })}
                handleButtonClick={onClickTransferOrder}
                disableClick={isLoading && 'clicks-disabled'}
            />
        </>
    );
}
