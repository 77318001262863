import React, { useState } from 'react';
import { useCartState } from '../../../../contexts/cart';
import { useIntl } from 'react-intl';
import AddItemCard from './AddItemCard';
import ArrowDown from '../../../../resources/images/arrow-down.svg';
import ViewAll from '../../../global/modules/viewAll/ViewAll';
import '../accessoriesAndAddons.scss';

const AddonsForPurchase = ({ selectedItems, consumableGroup, addAddon }) => {
    const [{ consumables }] = useCartState();
    const [isViewAll, setIsViewAll] = useState(false);
    const intl = useIntl();
    const maxAddOnsLimit = parseInt(document.querySelector('.checkout')?.dataset?.maxaddons);
    const maxQuantityLimit = parseInt(document.querySelector('.checkout')?.dataset?.maxaddonsquantity);
    const onClickHandler = () => {
        setIsViewAll(!isViewAll);
    };
    const renderItem = (item, indexItem) => {
        return (
            <li key={item?.id} className={`add-item-container ${indexItem !== 0 ? 'container-border' : ''}`}>
                <AddItemCard
                    title={item?.itemDescription}
                    sku={`${item?.itemNumber}${item?.stockClass}${item?.oemStockNumber}`}
                    type={intl.formatMessage({ id: 'checkout:rental-equipments-addons-type' })}
                    price={parseFloat(item?.sellingPrice)}
                    selectedItems={selectedItems?.addons}
                    handleValueChange={addAddon}
                    remainingQuantity={
                        consumables?.availableQuantity?.remainingAddOns[
                            `${item?.itemNumber}${item?.stockClass}${item?.oemStockNumber}`
                        ]?.addOnsRemainingQuantity
                    }
                    availableQuantity={
                        consumables?.availableQuantity?.remainingAddOns[
                            `${item?.itemNumber}${item?.stockClass}${item?.oemStockNumber}`
                        ]?.addOnsAvailableQuantity
                    }
                    isAddOns={true}
                    maxQuantityLimit={maxQuantityLimit}
                    itemObj={item}></AddItemCard>
            </li>
        );
    };
    return (
        <>
            <button
                className="expand-section"
                onClick={() => onClickHandler()}
                id={`${consumableGroup?.specNumber}-header`}
                data-testid={`${consumableGroup?.specNumber}-header`}
                aria-controls={`${consumableGroup?.specNumber}-panel`}
                aria-expanded={isViewAll}>
                <span>{consumableGroup?.title}</span>
                <span className={`expand_button ${isViewAll ? 'chevronExpend' : ''}`}>
                    <ArrowDown />
                </span>
            </button>
            {isViewAll && (
                <ul
                    id={`${consumableGroup?.specNumber}-panel`}
                    aria-labelledby={`${consumableGroup?.specNumber}-header`}
                    hidden={!isViewAll}>
                    <ViewAll
                        items={consumableGroup?.items}
                        renderItem={renderItem}
                        limit={maxAddOnsLimit}
                        viewAllClass="view-all-btn"
                    />
                </ul>
            )}
        </>
    );
};

export default AddonsForPurchase;
