import React from 'react';
import CollapsedAccessoriesAndAddons from './CollapsedAccessoriesAndAddons';
import EditAccessoriesAndAddons from './EditAccessoriesAndAddons';
import ReadOnlyAccessoriesAndAddons from './ReadOnlyAccessoriesAndAddons';
import '../howToGetYourOrder/howToGetOrder.scss';

const AccessoriesAndAddons = props => {
    const { currentStep, handleStepChange, currentOffSet, formStep, isCustomAccount, isAccessoriesAddonsAvailable } = props;
    const stepNumber = formStep.ACCESSORIES_AND_ADDONS;
    return (
        <div className={'howToGetOrder'}>
            {currentStep < stepNumber && <CollapsedAccessoriesAndAddons formStep={formStep} />}
            {currentStep === stepNumber && (
                <EditAccessoriesAndAddons
                    currentStep={currentStep}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                    isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyAccessoriesAndAddons handleStepChange={handleStepChange} formStep={formStep} />
            )}
        </div>
    );
};

export default AccessoriesAndAddons;
