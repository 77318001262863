import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../global/atoms/button/button';
import { useQuotesState } from '../quotes/context';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import './backToCart.scss';

const BackToCart = () => {
    const intl = useIntl();
    const [{ transmittedQuoteId }] = useQuotesState();
    const sucessfullyTransmittedQuoteId = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SUCCESSFULLY_TRANSMITTED_QUOTE_ID);

    useEffect(() => {
        const button = document?.getElementById('back-to-cart-header-btn');
        if (!transmittedQuoteId) {
            button?.classList?.add('visible');
        } else {
            button?.classList?.remove('visible');
        }
    }, [transmittedQuoteId, sucessfullyTransmittedQuoteId]);

    return (
        <>
        </>
    )
};

export default BackToCart;
