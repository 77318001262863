import { bool, func } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import EditIcon from '../../../../resources/images/edit.svg';
import '../../../global/atoms/editButton/editButton.scss';

export default function EditCartButton({ handleOnClick, testId, customButtonAriaLabel, isShowEditIcon }) {
    const intl = useIntl();
    const handleEditIconClick = () => {
        handleOnClick();
    };

    return (
        <button
            className="edit-button"
            onClick={handleEditIconClick}
            data-testid={testId}
            aria-label={'Edit ' + customButtonAriaLabel}>
            <span className="edit-label">
                {intl.formatMessage({ id: 'account:address-edit-in-text', defaultMessage: 'Edit in cart' })}
            </span>
            {isShowEditIcon && <span className="edit-icon">
            <EditIcon />
            </span>}
        </button>
    );
}

EditCartButton.propTypes = {
    handleOnClick: func,
    editText: bool,
    isShowEditIcon: bool
};

EditCartButton.defaultProps = {
    handleOnClick: () => {},
    editText: false,
    isShowEditIcon: true
};
