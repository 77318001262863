import React, { forwardRef } from 'react';
import { string, number, node } from 'prop-types';
import './editableView.scss';

const EditableView = (props,ref) => {
    const { children, currentStep, currentTitle, testId } = props;
    return (
        <div>
            <div className="editable-header">
                <span className="editable-orderSummaryHeadingNumber">{currentStep}</span>
                <h5  aria-label={currentTitle} className="editable-orderSummaryHeading" data-testid={testId} ref={ref}>
                    {currentTitle}
                </h5>
            </div>
            <div className="editable-body">{children}</div>
        </div>
    );
};

EditableView.propTypes = {
    children: node,
    currentStep: number,
    currentTitle: string,
    testId: string
};

EditableView.defaultProps = {
    children: null,
    currentStep: 0,
    currentTitle: '',
    testId: ''
};

export default forwardRef(EditableView);
